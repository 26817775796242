import styled from "styled-components";

const CardDividerContainer = styled.div<{ width?: string }>`
    display: flex;
    width: ${p => p.width ?? "100%"};
    gap: 0.4rem;
    justify-content: stretch;
    align-items: center;
`;

export default CardDividerContainer;