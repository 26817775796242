import { MethodHash, PaymentMethod } from "models";
import stripe from "stripe";
import { BraintreeGateway } from "braintree";
import braintree from "braintree";

export type HashablePaymentValues = { type: "paypal", email: string } | { type: "card", fingerprint: string, source: string };

const filterDuplicateCards = (paymentMethods: stripe.PaymentMethod[]): stripe.PaymentMethod[] => {
    const methods = paymentMethods.filter(it => it.type === "card");

    return methods.filter((v, i, a) => a.findIndex(it => it.card?.fingerprint === v.card?.fingerprint && it.card?.wallet?.type === v.card?.wallet?.type) === i);
}

export const comparePaymentMethods = (method1: PaymentMethod, method2: PaymentMethod) =>
    hashMethod(method1) === hashMethod(method2);

export const hashMethod = (method: HashablePaymentValues): MethodHash => `${method.type}:${method.type === "card" ? `${method.fingerprint}_${method.source}` : method.email}`;

export const toInternal = (out: stripe.PaymentMethod | braintree.PayPalAccount): PaymentMethod =>
    out instanceof braintree.PayPalAccount ? { type: "paypal", email: out.email } :
        { type: "card", id: out.id, brand: out.card?.brand ?? "", fingerprint: out.card?.fingerprint ?? "", last4: out.card!.last4, source: out.card?.wallet?.type ?? "stripe" }

const allMethods = async (Stripe: stripe, Braintree: BraintreeGateway, stripeCustomerId?: string, braintreeCustomerId?: string) => {
    const methods: PaymentMethod[] = (await Promise.all([
        (async () => {
            if(!stripeCustomerId) return [];
            const { data } = await Stripe.paymentMethods.list({
                customer: stripeCustomerId,
                type: "card"
            });
    
            return filterDuplicateCards(data).map(toInternal);
        })(),
        (async () => {
            if(!braintreeCustomerId) return [];
            const { paypalAccounts } = await Braintree.customer.find(braintreeCustomerId);

            if(!paypalAccounts) return [];

            return paypalAccounts.map(toInternal);
        })()
    ])).flat();

    return methods;
}

const chargeMethod = async (Stripe: stripe, Braintree: BraintreeGateway, stripeId: string, braintreeId: string, method: PaymentMethod, amount: number): Promise<boolean> => {
    if(method.type === "card" && stripeId) {
        try {
            await Stripe.paymentIntents.create({
                amount: amount * 100,
                currency: "usd",
                customer: stripeId,
                payment_method: method.id,
                off_session: true,
                confirm: true
            });
            return true;
        } catch {
            return false;
        }
    } else if(method.type === "paypal" && braintreeId) {
        const { paypalAccounts } = await Braintree.customer.find(braintreeId);
        const paypal = paypalAccounts?.find(it => it.email === method.email);
        if(!paypal)
            return false;

        const transaction = await Braintree.transaction.sale({
            amount: amount.toFixed(2),
            customerId: braintreeId,
            paymentMethodToken: paypal.token,
            transactionSource: "recurring",
            options: {
                submitForSettlement: true
            }
        })

        return transaction.success;
    }
    return false;
}

export const createPrivilegedCommons = (Stripe: stripe, Braintree: BraintreeGateway) => ({
    allMethods: (...args: [string?, string?]) => allMethods(Stripe, Braintree, ...args),
    chargeMethod: (...args: [string, string, PaymentMethod, number]) => chargeMethod(Stripe, Braintree, ...args)
})