import { useState } from "react";

import { useQuery } from "./useQuery";

export const useQueryMessages = () => {
    const query = useQuery();
    const [ queryErrors ] = useState<string[]>(query.errors as string[] ?? []);
    const [ queryMessages ] = useState<string[]>(query.messages as string[] ?? []);

    return { queryErrors, queryMessages };
};