import styled from "styled-components";

const TableRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 6px;
    border-bottom: 1px solid #989898;
    flex-shrink: 1;
    gap: 1rem;
`;

export default TableRowContainer;