import { McPasteSite } from "models";
import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useStoreActions, useStoreState } from "../../../../../hooks/state";
import { useTransform } from "../../../../../hooks/useHttp";
import ActionButtonElement from "../../../../elements/ActionButton";
import ActionButtonContainer from "../../../../elements/ActionButtonContainer";
import TableEntry from "../../../../elements/TableEntry";
import TableRowContainer from "../../../../elements/TableRowContainer";

const ActionButton = styled(ActionButtonElement)`
    width: 80px;
`;

type Props = {
    site: McPasteSite,
    ownerName: string,
    onSiteRemove: (site: string) => void
}

const AdminMcPasteSiteRow: FC<Props> = ({ site, ownerName, onSiteRemove }) => {
    const id = useStoreState(state => state.user.data!.id);

    const activateModal = useStoreActions(state => state.modals.activateModal);

    const [ deleteStatus, ,, deleteTransform ] = useTransform((axios) => axios.delete(`/modules/mcpaste/delete/${site.id}`,));

    useEffect(() => {
        if(deleteStatus !== "done") return;

        onSiteRemove(site.site);
    }, [deleteStatus]);

    const handleDelete = () => {
        activateModal({
            type: "confirm",
            data: {
                title: "Delete site?",
                message: "Are you sure you want to delete this site?",
                confirmButton: "Delete",
                onConfirm: deleteTransform
            }
        })
    }

    return (
        <TableRowContainer>
            <TableEntry>{site.site}</TableEntry>
            <TableEntry basis={40}>{site.token}</TableEntry>
            <TableEntry $center>{site.userId === id ? "You" : ownerName}</TableEntry>
            <TableEntry $center>
                <ActionButtonContainer>
                    <Link to={`/modules/mcpaste/configure/${site.id}`} style={{ textDecoration: "none" }}>
                        <ActionButton>Configure</ActionButton>
                    </Link>
                    <ActionButton onClick={handleDelete} disabled={deleteStatus === "loading"} $red={true}>Delete</ActionButton>
                </ActionButtonContainer>
            </TableEntry>
        </TableRowContainer>
    );
};

export default AdminMcPasteSiteRow;