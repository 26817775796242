import { createStore } from "easy-peasy";

import { auth, AuthStore } from "./auth";
import { modals, ModalStore } from "./modals";
import { user, UserStore } from "./user";

export type AppStore = {
    modals: ModalStore
    user: UserStore,
    auth: AuthStore
}

const state: AppStore = {
    modals,
    user,
    auth,
};

export const store = createStore(state, { name: "@hm4/state" });
