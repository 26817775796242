import styled from "styled-components";
import SectionContainer from "../../elements/SectionContainer";
import SectionHeader from "../../elements/SectionHeader";
import Totp from "./Totp";
import Webauthn from "./Webauthn";

const TwoFactorContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
`

export const AuthenticationMethodContainer = styled.div`
    display: flex;
    padding: 0.6rem;
    justify-content: space-between;
    border-bottom: 1px solid #c0c0c0;

    span:first-child {
        font-weight: 300;
    }
`

const ProfileTwoFactor = () => {
    return (
        <SectionContainer>
            <SectionHeader>
                <span>Two Factor Authentication</span>
            </SectionHeader>
            <TwoFactorContainer>
                <Totp />
                <Webauthn />
            </TwoFactorContainer>
        </SectionContainer>
    )
}

export default ProfileTwoFactor;