import styled from "styled-components";

import { withNav } from "../../../../hooks/withNavbar";
import ActiveServices from "./ActiveServices";
import PaymentMethods from "./PaymentMethods";
import TransactionTable from "./TransactionTable";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
`;

const BiContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    max-width: 1200px;

    @media (max-width: 1100px) {
        flex-wrap: wrap;
    }
`

const Billing = () => {
    return (
        <Container>
            <BiContainer>
                <PaymentMethods />
                <ActiveServices />
            </BiContainer>
            <TransactionTable />
        </Container>
    );
};

export default withNav(Billing);