import styled from "styled-components";

import FormikInput from "./FormikInput";

const TextInput = styled(FormikInput)`
    width: 100%;
    background: #F2F2F2;
    padding: 0.8rem 1.2rem;
    border: none;
    outline: none;
`;

export default TextInput;