import "nprogress/nprogress.css";

import { StoreProvider } from "easy-peasy";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import App from "./components/App";
import { store } from "./state";
import ConfirmModal from "./components/dashboard/ConfirmModal";

if(process.env.ENABLE_GOOGLE_ANALYTICS === "true") {
    ReactGA.initialize(process.env.GOOGLE_ANALYTICS_KEY!);
    ReactGA.pageview(location.pathname);
}

const GlobalStyle = createGlobalStyle`
    * {
        font-family: Roboto, Helvetica, sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html, body {
        overflow-x: hidden;
    }

    #nprogress {
        position: relative;
        z-index: 9999999;
    }
    #nprogress .bar {
        background: #040404 !important;
    }
    #nprogress .peg {
        box-shadow: 0 0 10px #040404, 0 0 5px #040404 !important;
    }
    #nprogress .spinner-icon {
        border-top-color: #040404 !important;
        border-left-color: #040404 !important;
    }
`;

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyle />
        <StoreProvider store={store}>
            <ConfirmModal />
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </StoreProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
