import { PaymentMethod } from "models";
import { comparePaymentMethods, hashMethod } from "payments";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useStoreActions } from "../../../../hooks/state";
import { useHttp } from "../../../../hooks/useHttp";
import CenteredLoadingSpinner from "../../../elements/CenteredLoadingSpinner";
import SectionContainer from "../../../elements/SectionContainer";
import SectionHeader from "../../../elements/SectionHeader";
import PaymentMethodContainer from "./PaymentMethodContainer";

const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
    padding: 0.8rem;
    overflow-x: auto;
    overflow-y: hidden;
`

const LinkWrapper = styled(Link)`
    color: #408CFF;
    text-decoration: underline;
`

const PaymentMethods = () => {
    const [ status, data ] = useHttp<PaymentMethod[]>(axios => axios.get("/payments/payment-methods"));

    const [ methods, setMethods ] = useState<PaymentMethod[]>([]);
    const [ errors, setErrors ] = useState<string[]>([]);

    useEffect(() => {
        if(status !== "done" || !data) return;
        setMethods(data);
    }, [status, data]);

    const onMethodRemove = (method: PaymentMethod) => {
        setMethods(methods => methods.filter(curr => !comparePaymentMethods(curr, method)));
    };

    const onSetPrimary = (primary: PaymentMethod) => {
        setMethods(methods => methods.map(curr => comparePaymentMethods(curr, primary) ? { ...curr, primary: true } : { ...curr, primary: undefined } ));
    };

    return (
        <SectionContainer>
            <SectionHeader>
                <span>Payment methods</span>
                {!!errors.length && <span style={{ color: "red" }}>{errors[0]}</span>}
                <LinkWrapper to="/account/billing/new">Add new</LinkWrapper>
            </SectionHeader>
            <RowContainer>
                {status === "loading" && <CenteredLoadingSpinner />}
                {methods.sort((a) => a.primary ? -1 : 1).map(it =>
                    <PaymentMethodContainer key={hashMethod(it)} method={it} onMethodRemove={onMethodRemove} onSetPrimary={onSetPrimary} setErrors={setErrors} />
                )}
            </RowContainer>
        </SectionContainer>
    )
}

export default PaymentMethods;