import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FC } from "react";

import { useBraintreeToken } from "../../hooks/useBraintreeToken";

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY!);

const paypalOptions = {
    "client-id": process.env.PAYPAL_CLIENT_ID!,
    currency: "USD",
    intent: "capture",
    "disable-funding": "card,credit,bancontact,paylater",
    vault: true
};

const PaymentElements: FC = ({ children }) => {
    const braintreeToken = useBraintreeToken();

    return (
        <Elements stripe={stripePromise}>
            {process.env.DISABLE_BRAINTREE !== "true" ?
                braintreeToken &&
                    <PayPalScriptProvider options={{
                        ...paypalOptions,
                        "data-client-token": braintreeToken
                    }}>
                        {children}
                    </PayPalScriptProvider>
            : children
            }
        </Elements>
    );
};

export default PaymentElements;