import styled from "styled-components";
import LoadingSpinner from "./LoadingSpinner";

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 0.4rem;
`;

const CenteredLoadingSpinner = () => {
    return (
        <Container>
            <LoadingSpinner />
        </Container>
    )
}

export default CenteredLoadingSpinner;