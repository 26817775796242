import { useCallback } from "react";
import styled from "styled-components";
import { useStoreActions, useStoreState } from "../../hooks/state";
import ActionButton from "../elements/ActionButton";
import Modal from "../elements/Modal";

const ButtonContainer = styled.div`
    display: flex;
    gap: 2rem;
`

const ConfirmModal = () => {
    const modal = useStoreState(state => state.modals.confirm);
    const deactivateModal = useStoreActions(state => state.modals.deactivateModal);

    const deactivateSelf = () => deactivateModal({ type: "confirm" });

    const onDecline = useCallback(() => {
        deactivateSelf();
        modal.onDecline();
    }, [modal]);

    const onConfirm = useCallback(() => {
        deactivateSelf();
        modal.onConfirm();
    }, [modal]);

    return (
        <Modal active={modal.active} title={modal.title} onExit={onDecline}>
            <span style={{ textAlign: "center" }}>{modal.message}</span>
            <ButtonContainer>
                <ActionButton onClick={onDecline}>
                    Cancel
                </ActionButton>
                <ActionButton onClick={onConfirm} $red>
                    {modal.confirmButton || "Confirm"}
                </ActionButton>
            </ButtonContainer>
        </Modal>
    )
}

export default ConfirmModal;