import { FC } from "react";
import { Snowflake } from "snowflake";
import styled from "styled-components";

import { useHttp } from "../../../../hooks/useHttp";
import { withNav } from "../../../../hooks/withNavbar";
import { generateAvatarURL } from "../../../../lib/gravatar";

type GamepanelAlphaFeedback = {
    id: Snowflake;
    title: string;
    description: string;
    url_at_submit: string;
    created_at: Date;
    panel: {
        id: Snowflake;
        host: string;
    };
    user: {
        id: Snowflake;
        panel_id: Snowflake;
        email: string;
        first_name: string;
        last_name: string;
        username: string;
    };
};

const Container = styled.div`
    display: grid;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    grid-template-columns: repeat(auto-fill, 400px);
`;

const FeedbackBox = styled.div`
    padding: 2rem;
    display: flex;
    flex-direction: column;
    background-color: white;
    gap: 0.4rem;
`;

const DetailContainer = styled.div`
    padding: 0.4rem;
    background-color: #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.4rem;
`;

const LinkWrapper = styled.a`
    color: #408cff;
    cursor: pointer;
`;

const FeedbacksContainer: FC = () => {
    const [status, feedbacks] = useHttp<GamepanelAlphaFeedback[]>((axios) =>
        axios.get("/admin/game-panel/feedbacks"),
    );

    if (status !== "done" || !feedbacks) return <></>;

    return (
        <Container>
            {feedbacks.map((feedback) => (
                <FeedbackBox key={feedback.id}>
                    <span
                        style={{
                            fontSize: "1.2rem",
                        }}
                    >
                        {feedback.title}
                    </span>
                    <span>{feedback.description}</span>
                    <div>
                        <span>Happened at: </span>
                        <span style={{ opacity: "0.6" }}>{feedback.url_at_submit}</span>
                    </div>
                    <DetailContainer>
                        <div
                            style={{
                                flexDirection: "column",
                                gap: "0.4rem",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    gap: "0.4rem",
                                    alignItems: "center",
                                }}
                            >
                                <span style={{ fontWeight: 500 }}>Panel</span>
                                <span style={{ opacity: "0.8", fontSize: "0.8rem" }}>
                                    {feedback.panel.id}
                                </span>
                            </div>
                            <LinkWrapper
                                href={`https://${feedback.panel.host}`}
                                style={{ textDecoration: "none" }}
                                target={"_blank"}
                            >
                                https://{feedback.panel.host}
                            </LinkWrapper>
                        </div>
                    </DetailContainer>
                    <DetailContainer>
                        <div
                            style={{
                                flexDirection: "column",
                                gap: "0.4rem",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    gap: "0.4rem",
                                    alignItems: "center",
                                }}
                            >
                                <span style={{ fontWeight: 500 }}>User</span>
                                <span style={{ opacity: "0.8", fontSize: "0.8rem" }}>
                                    {feedback.user.id}
                                </span>
                            </div>
                            <span>
                                {feedback.user.username} ({feedback.user.email})
                            </span>
                        </div>
                        <img
                            src={generateAvatarURL(feedback.user.email, 64)}
                            alt={"User avatar"}
                            style={{ height: "42px" }}
                        />
                    </DetailContainer>
                </FeedbackBox>
            ))}
        </Container>
    );
};

export default withNav(FeedbacksContainer);
