import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import { useTransform } from "../../hooks/useHttp";
import { withCaptcha } from "../../hooks/withCaptcha";
import Button from "../elements/Button";
import Card from "../elements/Card";
import CardDiv from "../elements/CardDiv";
import CardText from "../elements/CardText";
import CardTitle from "../elements/CardTitle";
import Copyright from "../elements/Copyright";
import Wrapper from "../elements/FullPageWrapper";
import LinkButton from "../elements/LinkButton";
import Modal from "../elements/Modal";
import TextInput from "../elements/TextInput";
import FormWrapper from "./elements/AuthFormWrapper";
import InputLabel from "./elements/AuthInputLabel";
import PasswordStrenghtIndicator, { PasswordStrenght } from "./elements/PasswordStrenghtIndicator";

const RegisterSchema = yup.object().shape({
    username: yup.string().required("Username is a required field!"),
    email: yup.string().email("Invalid Email!").required("Email is a required field!"),
    password: yup.string().required("Password is a required field!"),
    confirmPassword: yup.string().required("Password needs confirmation!").test(
        "confirm-password",
        "Passwords do not match",
        (value, context: { parent: { password: string } }) => context.parent.password === value
    )
});

type FormValues = {
    username: string,
    email: string,
    password: string,
    confirmPassword: ""
}

type RegisterValues = FormValues & { token: string }

const LinkWrapper = styled(Link)`
    color: #408CFF;
    text-decoration: underline;
`;

const Register = () => {

    const [ status, data, errors, transform ] = useTransform<unknown, RegisterValues>(
        (axios, credentials) => axios.post("/auth/register", credentials),
        { autoProccessErrors: false }
    );

    const { executeRecaptcha } = useGoogleReCaptcha();

    const [ captchaLoading, setCaptchaLoading ] = useState(false);

    const [ passwordStrenght, setPasswordStrenght ] = useState<PasswordStrenght>(null);

    const onSubmit = async (credentials: FormValues) => {
        setCaptchaLoading(true);
        if(!executeRecaptcha) {
            formik.setFieldError("captchaError", "Captcha failed to load, try refreshing the page.");
            setCaptchaLoading(false);
            return;
        }
        const token = await executeRecaptcha().finally(() => setCaptchaLoading(false));
        transform({ ...credentials, token });
    };

    useEffect(() => {
        if(status !== "done" && status !== "errored") return;
        if(data) return;
        
        formik.setFieldError("serverError", errors.join("; "));
    }, [status]);
    
    const formik = useFormik<FormValues>({
        initialValues: {
            username: "",
            email: "",
            password: "",
            confirmPassword: ""
        },
        validationSchema: RegisterSchema,
        validateOnChange: false,
        onSubmit
    });

    const checkPasswordStrength = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        if(!password) {
            setPasswordStrenght(null);
            return;
        }
    };

    return (
        <Wrapper>
            <Modal title="Account created!" active={status === "done" && !!data}>
                <CardText>Check your email for further insturctions!</CardText>
                <CardText>If you verified your email, you can feel free to login!</CardText>
                <LinkButton text="LOGIN" to="/" />
            </Modal>
            <Card>
                <CardTitle>
                    Register
                </CardTitle>
                {Object.entries(formik.errors).filter(([k]) => !Object.keys(formik.values).includes(k)).map(([k, v]) => <span key={k} style={{ color: "red" }}>{v}</span>)}
                <FormWrapper onSubmit={formik.handleSubmit}>
                    <CardDiv>
                        <InputLabel text="Email" error={formik.errors.email} />
                        <TextInput type="text" placeholder="some@example.com" name="email" formik={formik} />
                    </CardDiv>
                    <CardDiv>
                        <InputLabel text="Username" error={formik.errors.username} />
                        <TextInput type="text" placeholder="John" name="username" formik={formik} />
                    </CardDiv>
                    <CardDiv>
                        <InputLabel text="Password" error={formik.errors.password} />
                        <div style={{ position: "relative" }}>
                            <TextInput type="password" placeholder="******" name="password" formik={formik} onChange={e => {
                                formik.handleChange(e);
                                checkPasswordStrength(e);
                            }}/>
                            <PasswordStrenghtIndicator visible={passwordStrenght !== null} strenght={passwordStrenght as NonNullable<PasswordStrenght>} />
                        </div>
                    </CardDiv>
                    <CardDiv>
                        <InputLabel text="Confirm Password" error={formik.errors.confirmPassword} />
                        <TextInput type="password" placeholder="******" name="confirmPassword" formik={formik} />
                    </CardDiv>
                    <Button disabled={captchaLoading || status === "loading"}>REGISTER</Button>
                </FormWrapper>
                <CardText>
                    already have an account?
                    <LinkWrapper to="/">
                        login
                    </LinkWrapper>
                </CardText>
            </Card>
            <Copyright />
        </Wrapper>
    );
};

export default withCaptcha(Register);