import styled from "styled-components";

const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #989898;
    font-size: 1rem;

    * {
        margin: 0 0.6rem;
    }

    span:first-child {
        margin: 0.6rem;
    }
`

export default SectionHeader;