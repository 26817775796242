import { Action, action, persist } from "easy-peasy";

export type AuthStore = {
    token: string,
    setToken: Action<AuthStore, string>
}

export const auth = persist<AuthStore>({
    token: "",
    setToken: action((state, token) => { state.token = token; })
}, { storage: "localStorage" });