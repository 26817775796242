import styled from "styled-components";

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 4px;
    width: 100%;
    max-width: 1200px;
    background-color: white;
`

export default SectionContainer;