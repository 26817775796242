import qs from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useQuery = <T extends object = { _raw: string } & { [k: string]: any }>(mod: (data: { _raw: string } & { [k: string]: any }) => T = (data) => data as T): T => {
    const { search } = useLocation();

    return useMemo(() => {
        if(search.length === 0)
            return mod({ _raw: "" });
        const _raw = search.substring(1);
        const query = qs.parse(_raw);
        return mod({ _raw, ...Array.from(Object.entries(query)).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) });
    }, [search]);
};