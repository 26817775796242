import { Action, action } from "easy-peasy";
import { User } from "models";

export type UserStore = {
    data?: User,
    setUserData: Action<UserStore, User | undefined>,
    updateUserData: Action<UserStore, Partial<User>>,
}

export const user: UserStore = {
    data: undefined,
    setUserData: action((state, payload) => {
        state.data = payload;
    }),
    updateUserData: action((state, payload) => {
        if(!state.data) return;
        state.data = {
            ...state.data,
            ...payload
        };
    })
};