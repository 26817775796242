import { FC } from "react";
import styled from "styled-components";
import HM4Logo from "url:/assets/favicon.ico";

const WrapperContainer = styled.div`
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(252deg, #F7F5F5 4.28%, #c4c4c4 100%);
    gap: 1rem;
    color: rgb(10, 10, 10);
`;

const LogoContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 2rem;
    top: 2rem;
    gap: 0.2rem;
    
    @media (max-width: 768px) {
        position: unset;
    }
`;

const LogoTitle = styled.div`
    font-weight: 800;
    font-size: 2rem;
    padding-top: 0.6rem;
`;

const Wrapper: FC = ({ children }) => {
    return (
        <WrapperContainer>
            <LogoContainer>
                <img style={{ height: "4rem" }} src={ HM4Logo } />
                <LogoTitle>HM4</LogoTitle>
            </LogoContainer>
            {children}
        </WrapperContainer>
    );
};

export default Wrapper;