import qs from "qs";
import { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useHistory } from "react-router-dom";
import { Snowflake } from "snowflake";

import { useStoreActions } from "../../hooks/state";
import { useAuth } from "../../hooks/useAuth";
import { useTransform } from "../../hooks/useHttp";
import { useQuery } from "../../hooks/useQuery";
import { withCaptcha } from "../../hooks/withCaptcha";
import FullPageLoading from "../elements/FullPageLoading";

type DiscordQuery = {
    code: string
}

const Link = () => {
    const history = useHistory();

    const { code } = useQuery();

    const updateUserData = useStoreActions(store => store.user.updateUserData);

    const [ status, data, errors, transform ] = useTransform<{ discordId: Snowflake }, DiscordQuery>((axios, query) => axios.post("/discord/link", query));

    useEffect(() => {
        transform({ code: code || "" });
    }, []);

    useEffect(() => {
        if(status === "errored")
            history.push(`/account?${qs.stringify({ errors: errors })}`);

        if(status !== "done" || !data) return;

        updateUserData({ discordId: data.discordId });
        history.push("/account");
    }, [status]);

    return (
        <FullPageLoading text="Linking your account..." />
    );
};

type CaptchaQuery = DiscordQuery & { token: string }

const Login = withCaptcha(() => {
    const history = useHistory();

    const { code } = useQuery();

    const { setToken } = useAuth();

    const [ status, data, errors, transform ] = useTransform<{ token: string }, CaptchaQuery>((axios, query) => axios.post("/discord/login", query));
    
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        if(!executeRecaptcha) return;
        executeRecaptcha()
            .then(token => transform({ code: code || "", token }));
    }, [executeRecaptcha]);

    useEffect(() => {
        if(status === "errored")
            history.push(`/?${qs.stringify({ errors: errors })}`);

        if(status !== "done" || !data) return;

        setToken(data.token);
        history.push("/");
    }, [status]);

    return (
        <FullPageLoading text="Logging you in..." />
    );
});

const DiscordCallback = {
    Link,
    Login
};

export default DiscordCallback;