import styled from "styled-components";
import DiscordLogo from "url:/assets/discord.svg";

import Button from "./Button";

const DiscordButtonContainer = styled(Button)`
    background-color: #5865F2;

    &:hover {
        background-color: #4e5ada;
    }
`;

const DiscordButton = ({ text }: { text: string }) => {
    return (
        <DiscordButtonContainer>
            <img src={ DiscordLogo } style={{ height: "1rem" }} />
            { text }
        </DiscordButtonContainer>
    );
};

export default DiscordButton;