import { User } from "models";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useStoreActions, useStoreState } from "../hooks/state";
import { useAuth } from "../hooks/useAuth";
import { clearRequestCache, doRequest } from "../hooks/useHttp";
import DiscordCallback from "./auth/DiscordCallback";
import EmailConfirm from "./auth/EmailConfirm";
import Login from "./auth/Login";
import PasswordReset from "./auth/PasswordReset";
import Register from "./auth/Register";
import Verification from "./auth/Verification";
import Cron from "./dashboard/admin/Cron";
import Statistics from "./dashboard/admin/Statistics";
import Users from "./dashboard/admin/Users";
import Dashboard from "./dashboard/dashboard/DashboardContainer";
import McPasteConfiguration from "./dashboard/modules/mcpaste/configure/McPasteConfiguration";
import McPasteContainer from "./dashboard/modules/mcpaste/McPasteContainer";
import RedirectContainer from "./dashboard/modules/redirect/RedirectContainer";
import PaymentProcess from "./dashboard/payments/PaymentProcess";
import AddPaymentMethod from "./dashboard/profile/billing/AddPaymentMethod";
import Billing from "./dashboard/profile/billing/Billing";
import Profile from "./dashboard/profile/Profile";
import CardText from "./elements/CardText";
import CardTitle from "./elements/CardTitle";
import Fade from "./elements/Fade";
import FullPageLoading from "./elements/FullPageLoading";
import Wrapper from "./elements/FullPageWrapper";
import LinkButton from "./elements/LinkButton";
import Panels from "./dashboard/admin/gamepanel/Panels";
import FeedbacksContainer from "./dashboard/admin/gamepanel/FeedbacksContainer";

const LoggedOutContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`;

const App = () => {
    const user = useStoreState((store) => store.user.data);
    const setUser = useStoreActions((store) => store.user.setUserData);

    const { valid } = useAuth();
    const location = useLocation();
    const history = useHistory();

    const [skipValidCheck, setSkipValidCheck] = useState(true);

    useEffect(() => {
        ReactGA.pageview(location.pathname);
    }, [location]);

    useEffect(() => {
        if (valid || skipValidCheck) return setSkipValidCheck(false);
        setUser(undefined);
        history.push("/");
    }, [valid]);

    useEffect(() => {
        if (!valid || user || location.pathname === "/") return;
        clearRequestCache();
        doRequest<User>((axios) => axios.get("/user/info")).then(([user]) => setUser(user));
    }, [valid]);

    useEffect(() => {
        const t = setTimeout(() => {
            for (let i = 0; i < 2; i++) {
                console.log("%cHey! Watch out!", "color: red; font-size: 60px");
                console.log(
                    "%cIf you do not understand exactly what you're doing, please close this window!",
                    "font-size: 16px",
                );
            }
        }, 2000);

        return () => clearTimeout(t);
    }, []);

    return (
        <div>
            <Fade in={!user} timeout={400} unmountOnExit>
                {location.pathname === "/" || !valid ? (
                    <LoggedOutContainer>
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <Route
                                exact
                                path="/auth/verify/:userId/:token"
                                component={Verification}
                            />
                            <Route
                                exact
                                path="/auth/reset-password/:userId/:token"
                                component={PasswordReset}
                            />
                            <Route
                                exact
                                path="/auth/update-email/:userId/:token"
                                component={EmailConfirm}
                            />
                            <Route exact path="/discord/login" component={DiscordCallback.Login} />
                            <Route path="*">
                                <Wrapper>
                                    <CardTitle>404</CardTitle>
                                    <CardText>Looks like you got lost</CardText>
                                    <LinkButton text="Back to Login" to="/" width="400px" />
                                </Wrapper>
                            </Route>
                        </Switch>
                    </LoggedOutContainer>
                ) : (
                    <FullPageLoading funify />
                )}
            </Fade>
            {user && (
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/account" component={Profile} />
                    <Route exact path="/account/billing" component={Billing} />
                    <Route exact path="/account/billing/new" component={AddPaymentMethod} />
                    <Route exact path="/modules/mcpaste" component={McPasteContainer} />
                    <Route
                        exact
                        path="/modules/mcpaste/configure/:id"
                        component={McPasteConfiguration}
                    />
                    <Route exact path="/modules/redirect" component={RedirectContainer} />
                    <Route exact path="/discord/link" component={DiscordCallback.Link} />
                    <Route exact path="/auth/verify/:userId/:token" component={Verification} />
                    <Route
                        exact
                        path="/auth/reset-password/:userId/:token"
                        component={PasswordReset}
                    />
                    <Route
                        exact
                        path="/auth/update-email/:userId/:token"
                        component={EmailConfirm}
                    />
                    <Route exact path="/payments/process" component={PaymentProcess} />
                    {user.admin && <Route exact path="/admin/users" component={Users} />}
                    {user.admin && <Route exact path="/admin/panels" component={Panels} />}
                    {user.admin && (
                        <Route
                            exact
                            path="/admin/panels/feedbacks"
                            component={FeedbacksContainer}
                        />
                    )}
                    {user.admin && <Route exact path="/admin/statistics" component={Statistics} />}
                    {user.admin && <Route exact path="/admin/cron" component={Cron} />}
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            )}
        </div>
    );
};

export default App;
