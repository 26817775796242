import { faCcDinersClub, faPaypal } from "@fortawesome/free-brands-svg-icons";
import { faCircle, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { PaymentMethod } from "models";
import { useEffect } from "react";
import { FC } from "react";
import styled from "styled-components";

import { useTransform } from "../../../../hooks/useHttp";
import Icon from "../../../elements/Icon";
import ActionButton from "../../../elements/ActionButton";
import VisaMark from "url:/assets/visa.svg";
import MastercardMark from "url:/assets/mastercard.svg";
import AmexMark from "url:/assets/amex.svg";
import DiscoverMark from "url:/assets/discover.jpg";
import JCBMark from "url:/assets/jcb.png";
import GooglePayMark from "url:/assets/google-pay.png";
import { useStoreActions } from "../../../../hooks/state";

const Container = styled.div`
    width: 100%;
    min-width: 540px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem;
    border-bottom: 1px solid #c3c3c3;
    gap: 0.4rem;
`;

const ContainerPart = styled.div`
    display: flex;
    gap: 0.6rem;
    align-items: center;
`;

export const MethodIconContainer = styled.div`
    display: flex;
    width: 40px;
    justify-content: center;
    align-items: center;
`

const PrimaryTag = styled.span`
    color: #5CBE77;
    font-weight: 500;
`;

const CardDigitContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.4rem;
`;

export const CardDigitQuartet = () => {
    return (
        <CardDigitContainer>
            {Array.from(Array(4).keys()).map(n => <Icon key={n} icon={faCircle} style={{ width: "6px", color: "rgb(10, 10, 10)" }} />)}
        </CardDigitContainer>
    );
};

const IconImg = styled.img`
    height: auto;
    width: 24px;
`

export const getPaymentMethodIconElement = (method: PaymentMethod) => {
    if(method.type === "paypal")
        return <Icon icon={faPaypal} color={"#0079C1"} size="lg" />

    if(method.source === "google_pay")
        return <IconImg src={GooglePayMark} />

    switch(method.brand) {
        case "visa":
            return <IconImg src={VisaMark} style={{ width: "40px" }} />
        case "mastercard":
            return <IconImg src={MastercardMark} />
        case "amex":
            return <IconImg src={AmexMark} />
        case "discover":
            return <IconImg src={DiscoverMark} />
        case "jcb":
            return <IconImg src={JCBMark} />
        case "diners":
            return <Icon icon={faCcDinersClub} size="lg" color={"#0069AA"} />
        default:
            return <Icon icon={faCreditCard} size="lg" color={"#525252"} />
    }
}

type Props = {
    method: PaymentMethod,
    onMethodRemove: (method: PaymentMethod) => void,
    onSetPrimary: (primary: PaymentMethod) => void,
    setErrors: (errors: string[]) => void
}

const PaymentMethodContainer: FC<Props> = ({ method, onMethodRemove, onSetPrimary, setErrors }) => {
    const activateModal = useStoreActions(state => state.modals.activateModal);

    const [ removeStatus,, errors, removeTransform ] = useTransform((axios) =>
        method.type === "card" ?
            axios.post("/payments/stripe/remove-card", { fingerprint: method.fingerprint, source: method.source }) :
            axios.post("/payments/braintree/remove-account", { email: method.email })
    );

    const [ primaryStatus,,, primaryTransform ] = useTransform(axios => axios.post("/payments/make-primary",
        method.type === "card" ?
            { fingerprint: method.fingerprint, source: method.source } :
            { email: method.email }
    ));

    useEffect(() => {
        if(removeStatus !== "done") return;

        onMethodRemove(method);
    }, [removeStatus]);

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        if(primaryStatus !== "done") return;

        onSetPrimary(method);
    }, [primaryStatus]);

    const handleRemove = () => {
        activateModal({
            type: "confirm",
            data: {
                title: "Remove payment method?",
                message: `Are you sure you want to remove ${method.type === "card" ? `card: **** ${method.last4}` : `paypal: ${method.email}`} from your payment methods?`,
                confirmButton: "Remove",
                onConfirm: removeTransform
            }
        })
    }

    return (
        <Container>
            <ContainerPart>
                <MethodIconContainer>{getPaymentMethodIconElement(method)}</MethodIconContainer>
                {method.type === "card" && <CardDigitQuartet />}
                {method.type === "card" ? method.last4 : method.email}
            </ContainerPart>
            <ContainerPart>
                {method.primary ?
                    <PrimaryTag>primary</PrimaryTag>
                :
                    <ActionButton style={{ width: "100px" }} onClick={primaryTransform} disabled={primaryStatus === "loading"}>Make primary</ActionButton>
                }
                <ActionButton onClick={handleRemove} disabled={removeStatus === "loading"} $red>Remove</ActionButton>
            </ContainerPart>
        </Container>
    );
};

export default PaymentMethodContainer;