import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { CSSProperties, FC } from "react";

import Button from "./Button";
import Icon from "./Icon";

type Props = {
    icon: IconProp,
    width?: string
    style?: CSSProperties
}

const IconButton: FC<React.ButtonHTMLAttributes<HTMLButtonElement> & Props> = ({ icon, width, style, children, ...props }) => {
    return (
        <Button style={{ justifyContent: "space-between", ...style }} width={width} {...props}>
            <span></span>
            {children}
            <Icon icon={icon} />
        </Button>
    );
};

export default IconButton;