import { FC } from "react";
import styled from "styled-components";

export type PasswordStrenght = 0 | 1 | 2 | 3 | 4 | null

const colors: { [key in NonNullable<PasswordStrenght>]: string } = {
    0: "#ff0000",
    1: "#ffA500",
    2: "#d5b134",
    3: "#0bc7c7",
    4: "#2ccc2c"
};

const PasswordStrenghtIndicatorContainer = styled.div<{ $visible: boolean }>`
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: ${p => p.$visible ? 1 : 0};
    transition: 200ms linear;
    transition: opacity 60ms linear;
`;

const PasswordStrenghtIndicatorBar = styled.div<{ strenght: NonNullable<PasswordStrenght> }>`
    width: ${p => 20 * (p.strenght + 1)}%;
    height: 4px;
    background-color: ${p => colors[p.strenght]};
    transition: 200ms linear;
`;

const PasswordStrenghtIndicator: FC<{ strenght: NonNullable<PasswordStrenght>, visible: boolean }> = ({ strenght, visible }) => {
    return (
        <PasswordStrenghtIndicatorContainer $visible={visible}>
            <PasswordStrenghtIndicatorBar strenght={strenght} />
            {/* <span style={{ color: colors[strenght] }}>{ messages[strenght] }</span> */}
        </PasswordStrenghtIndicatorContainer>
    );
};

export default PasswordStrenghtIndicator;