import { PasswordlessUser } from "models";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";

import { useStoreActions, useStoreState } from "../../../hooks/state";
import { useTransform } from "../../../hooks/useHttp";
import { formatDateTime } from "../../../lib/date";
import ActionButton from "../../elements/ActionButton";
import ActionButtonContainer from "../../elements/ActionButtonContainer";
import TableEntry from "../../elements/TableEntry";
import TableRowContainer from "../../elements/TableRowContainer";

const Centered = styled.div`
    text-align: center;
`;

type Props = {
    user: PasswordlessUser;
};

const AdminUserContainer = ({ user: userParam }: Props) => {
    const id = useStoreState((state) => state.user.data!.id);
    const [user, setUser] = useState(userParam);

    const activateModal = useStoreActions((state) => state.modals.activateModal);

    const [status, data, , transform] = useTransform<{ newState: boolean }>((axios) =>
        axios.post("/admin/toggle", { id: user.id }),
    );

    useEffect(() => {
        if (status !== "done" || !data) return;
        setUser((user) => ({ ...user, admin: data.newState }));
    }, [status]);

    useEffect(() => {
        setUser(userParam);
    }, [userParam]);

    const formatAttributes = (user: PasswordlessUser) => {
        const attr = [];
        if (user.verified) attr.push("Verified");
        if (user.beta) attr.push("Beta");
        if (user.admin) attr.push("Admin");
        return attr.length ? attr.join(", ") : "None";
    };

    const handleDelete = () => {
        activateModal({
            type: "confirm",
            data: {
                title: `${user.admin ? "Demote" : "Promote"} ${user.username}?`,
                message: `Are you sure you want to ${user.admin ? "demote" : "promote"} ${user.username} (${user.email}) ${user.admin ? "from" : "to"} administrator?`,
                onConfirm: transform,
            },
        });
    };

    return (
        <TableRowContainer style={{ gap: "1rem" }}>
            <TableEntry basis={19}>{user.id}</TableEntry>
            <TableEntry basis={23}>
                {user.username} (<span style={{ color: "#636363" }}>{user.email}</span>)
            </TableEntry>
            <TableEntry basis={19}>{user.discordId ?? "Not set"}</TableEntry>
            <TableEntry basis={13}>
                <Centered>{formatAttributes(user)}</Centered>
            </TableEntry>
            <TableEntry basis={13}>
                <Centered>{formatDateTime(new Date(user.createdAt))}</Centered>
            </TableEntry>
            <TableEntry basis={13}>
                <ActionButtonContainer>
                    <ActionButton
                        style={{ width: "100px" }}
                        disabled={id === user.id || status === "loading"}
                        onClick={handleDelete}
                        $red
                    >
                        {user.admin ? "Take" : "Make"} admin
                    </ActionButton>
                </ActionButtonContainer>
            </TableEntry>
        </TableRowContainer>
    );
};

export default AdminUserContainer;
