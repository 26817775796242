import { useEffect, useState } from "react";

import { useHttp } from "../../../../hooks/useHttp";
import { formatOnlyDate, subtractDateDays } from "../../../../lib/date";
import SimpleLineChart from "../../../elements/SimpleLineChart";

type ChartableTransaction = { amount: number, date: string };

const SystemCharts = () => {
    const [ transactions, setTransactions ] = useState<ChartableTransaction[]>(
        new Array(30).fill(0).map((_, i) => ({ amount: 0, date: formatOnlyDate(subtractDateDays(new Date(), i)) }))
    );
    const [ transactionStatus, transactionData ] = useHttp<ChartableTransaction[]>(axios => axios.get("/admin/chart/transactions"));

    useEffect(() => {
        if(transactionStatus !== "done" || !transactionData) return;
        setTransactions(transactionData);
    }, [transactionStatus, transactionData]);

    return (
        <div>
            <SimpleLineChart
                title="Transactions (30d)"
                width={900}
                data={transactions}
                lineDataKey="amount"
                nameDataKey="date"
                tooltipPayloadMod={(i) => `Amount: $${i}`}
            />
        </div>
    );
};

export default SystemCharts;