import styled from "styled-components";

import { withNav } from "../../../hooks/withNavbar";
import McPasteCharts from "./charts/McPasteCharts";
import SystemCharts from "./charts/SystemCharts";

export const ChartContainer = styled.div`
    background-color: white;
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 1rem;
`;

const Statistics = () => {
    return (
        <div>
            <SystemCharts />
            <McPasteCharts />
        </div>
    );
};

export default withNav(Statistics);