import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { McPasteSite } from "models";
import { Plans } from "models";
import { FC, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import { useTransform } from "../../../../../hooks/useHttp";
import { withIdData } from "../../../../../hooks/withIdData";
import { withNav } from "../../../../../hooks/withNavbar";
import { formatOnlyDate } from "../../../../../lib/date";
import { stringifyPaymentData } from "../../../../../lib/paymentDataParser";
import Button from "../../../../elements/Button";
import GenericFlexCenter from "../../../../elements/GenericFlexCenter";
import Icon from "../../../../elements/Icon";
import LoadingSpinner from "../../../../elements/LoadingSpinner";
import SectionContainer from "../../../../elements/SectionContainer";
import SectionHeader from "../../../../elements/SectionHeader";
import CosmeticOptions from "./CosmeticOptions";

export type McPasteSiteDisplay = McPasteSite & { cancelled: boolean, expires_on: Date };

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
`;

const LinkWrapper = styled(Link)`
    color: #408CFF;
    text-decoration: underline;
`;

const PlanDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 0.4rem;
`

const SiteName = styled.div`
    font-size: 1.1rem;
`

const Plan = styled.div<{ $premium: boolean }>`
    font-size: 2.4rem;
    color: ${p => p.$premium ? "#cfb000" : "#0a0a0a"};
    text-transform: uppercase;
`

const Expiry = styled.div`
    font-size: 1rem;
    color: #323232;
`

const PlansContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
    padding: 1rem;
`

const PlanCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    background-color: #F6F6F6;
    border-radius: 4px;
    height: 420px;
    width: 260px;
    padding: 1rem;
    font-size: 1rem;

    span:nth-child(1) {
        font-size: 1.4rem;
    }

    span:nth-child(2) {
        font-size: 1.1rem;
    }
`

const PerkList = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.2rem;
    padding: 0.8rem;
`

const PlanPerkContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 1rem;
`

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
`

const UpgradeButton = styled(Button)`
    border-radius: 10px;
    padding: 0.6rem;

    &[disabled] {
        background-color: #5f5f5f;
    }
`

const AWrapper = styled.a`
    color: #408CFF;
    text-decoration: none;
    cursor: pointer;
    margin: 0.4rem 0 0 0;

    &:hover {
        text-decoration: underline;
    }
`;

const PlanPerk: FC = ({ children }) => {
    return (
        <PlanPerkContainer>
            {children}
            <Icon icon={faCheckCircle} style={{ width: "12px", height: "auto", color: "#5CBE77" }} />
        </PlanPerkContainer>
    )
}

const McPasteConfiguration: FC<{ site: McPasteSiteDisplay }> = ({ site: siteParam }) => {
    const [ cancelStatus,,,cancelTransform ] = useTransform(axios => axios.post("/payments/cancel", { service: "mcpaste", metadata: { token: site.token } }));

    const [ site, setSite ] = useState({ ...siteParam, expires_on: new Date(siteParam.expires_on) });

    const [ plansVisible, setPlansVisible ] = useState(site.plan === "Free");

    useEffect(() => {
        if(cancelStatus !== "done") return;

        setSite(site => ({ ...site, ...(site.expires_on.getFullYear() === 9999 ? { plan: "Free" } : { cancelled: true }) }));
    }, [cancelStatus]);

    const planIndex = useMemo(() => Plans["mcpaste"].findIndex(it => it.name.toLowerCase() === site.plan.toLowerCase()), [site]);

    return (
        <Container>
            <SectionContainer>
                <SectionHeader>
                    <span>Overview</span>
                    <LinkWrapper to="/modules/mcpaste">Back</LinkWrapper>
                </SectionHeader>
                <PlanDataContainer>
                    <SiteName>{site.site}</SiteName>
                    <Plan $premium={site.plan !== "Free"}>{site.plan}</Plan>
                    {site.expires_on.getFullYear() !== 9999 && <Expiry>{site.cancelled && 'cancelled - '} expires on {formatOnlyDate(site.expires_on)}</Expiry>}
                    {site.plan !== "Free" && <AWrapper onClick={() => setPlansVisible(v => !v)}>{plansVisible ? "hide plans" : "show plans"}</AWrapper>}
                </PlanDataContainer>
                {plansVisible &&
                    <PlansContainer>
                        {Plans["mcpaste"].map(plan =>
                            <PlanCard key={plan.name}>
                                <span>{plan.name}</span>
                                <span>&#36;{plan.price}/mo.</span>
                                <PerkList>
                                    {plan.perks.map(perk => 
                                        <li><PlanPerk>{perk}</PlanPerk></li>
                                    )}
                                </PerkList>
                                {!!(plan.price && site.plan !== plan.name || site.plan === plan.name && site.cancelled) &&
                                    <ButtonContainer>
                                        <Link to={`/payments/process?${stringifyPaymentData({
                                            service: "mcpaste",
                                            plan: plan.name,
                                            metadata: {
                                                token: site.token
                                            }
                                        })}`} style={{ textDecoration: "none", cursor: plan.available ? "pointer" : "default" }}>
                                            <UpgradeButton disabled={!plan.available}>
                                                {plan.available ? site.plan === plan.name && site.cancelled ? "RENEW" : "UPGRADE" : "COMMING SOON"}
                                            </UpgradeButton>
                                        </Link>
                                    </ButtonContainer>
                                }
                            </PlanCard>
                        )}
                    </PlansContainer>
                }
            </SectionContainer>
            {planIndex >= 1 &&
                <CosmeticOptions site={site} />
            }
        </Container>
    );
};

export default withNav(withIdData<McPasteSiteDisplay, "site">("site", (axios, id) => axios.get(`modules/mcpaste/${id}`), McPasteConfiguration, () => (
    <GenericFlexCenter style={{ flexGrow: 1 }}>
        <LoadingSpinner size="BIG" />
    </GenericFlexCenter>
), () => {
    const history = useHistory();

    useEffect(() => {
        history.push("/modules/mcpaste");
    }, []);
    return <></>;
}));