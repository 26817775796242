import styled from "styled-components";

const TableEntry = styled.div<{ $center?: boolean, basis?: number }>`
    font-size: 1rem;
    flex-basis: ${p => p.basis ?? 20}%;
    font-weight: 300;
    word-break: break-word;
    ${p => p.$center ? "text-align: center;" : ""}
`;

export default TableEntry;