import { FC } from "react";

import Navigation from "../components/dashboard/Navigation";

export const withNav = (Component: FC): FC => {
    return () => (
        <Navigation>
            <Component />
        </Navigation>
    );
};