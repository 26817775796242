import { FC } from "react";
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import styled from "styled-components";

import { ChartContainer } from "../dashboard/admin/Statistics";

const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
    gap: 0.6rem;
    color: white;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.8);

    span:first-child {
        font-weight: 600;
    }
`;

const CustomTooltip = ({ payload, label, active, mod }: { payload?: { value: string | number }[], label?: string, active?: boolean, mod: (data: string) => string }) => {
    return active && payload ? (
        <TooltipContainer>
            <span>{label}</span>
            <span>{mod(payload[0].value.toString())}</span>
        </TooltipContainer>
    ) : null;
};

type Props = {
    title: string,
    width?: number,
    height?: number,
    data: any[],
    lineDataKey: string,
    nameDataKey: string,
    tooltipPayloadMod: (data: string) => string
}

const SimpleLineChart: FC<Props> = ({ title, width = 500, height = 250, data, lineDataKey, nameDataKey, tooltipPayloadMod }) => {
    return (
        <ChartContainer>
            <span>{ title }</span>
            <AreaChart width={width} height={height} data={data}>
                <defs>
                    <linearGradient id={`${lineDataKey}-chartGradient`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#3d7fe9" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#3d7fe9" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                {/* <Line type="monotone" dataKey={lineDataKey} stroke="#040404" animationDuration={400} /> */}
                <Area type="monotone" dataKey={lineDataKey} stroke="#3d7fe9" fillOpacity={1} fill={`url(#${lineDataKey}-chartGradient)`} />
                <CartesianGrid />
                <XAxis dataKey={nameDataKey} />
                <YAxis />
                <Tooltip content={<CustomTooltip mod={tooltipPayloadMod} />} />
            </AreaChart>
        </ChartContainer>
    );
};

export default SimpleLineChart;