import styled from "styled-components";

const FormWrapper = styled.form`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
`;

export default FormWrapper;