import { FormikHandlers, FormikState } from "formik";
import { McPasteSite, User } from "models";
import { FC } from "react";
import styled from "styled-components";

import { useStoreState } from "../../../../../hooks/state";
import { useHttp } from "../../../../../hooks/useHttp";
import { capitalize } from "../../../../../lib/text";
import ErrorContainer from "../../../../elements/ErrorContainer";
import LimitedContentContainer from "../../../../elements/LimitedContentContainer";
import LoadingSpinner from "../../../../elements/LoadingSpinner";
import TableContainer from "../../../../elements/TableContainer";
import TableContentContainer from "../../../../elements/TableContentContainer";
import TableHeading from "../../../../elements/TableHeading";
import TableHeadingContainer from "../../../../elements/TableHeadingContainer";
import TableInput from "../../../../elements/TableInput";
import TableInputButton from "../../../../elements/TableInputButton";
import TableInputContainer from "../../../../elements/TableInputContainer";
import TableWrapper from "../../../../elements/TableWrapper";
import AdminMcPasteSiteRow from "./AdminMcPasteSiteRow";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
`;

type Props = {
    formik: FormikHandlers & FormikState<{ site: string }>,
    sites: McPasteSite[],
    loading: boolean,
    disabled: boolean,
    onSiteRemove: (site: string) => void
}

const AdminMcPasteContainer: FC<Props> = ({ formik, sites, loading, disabled, onSiteRemove }) => {
    const { id, admin } = useStoreState(state => state.user.data!);

    const [ ,users ] = useHttp<Omit<User, "password">[]>(axios => axios.get("/admin/users"), { doRequest: admin });

    return (
        <Container>
            <ErrorContainer>
                {Object.values(formik.errors).map(it => <span key={it} style={{ color: "red" }}>{capitalize(it)}</span>)}
            </ErrorContainer>
            <LimitedContentContainer>
                <TableInputContainer onSubmit={formik.handleSubmit}>
                    <TableInput name="site" placeholder="sub.domain.tld" formik={formik} />
                    <TableInputButton disabled={disabled}>ADD</TableInputButton>
                </TableInputContainer>
                <TableWrapper>
                    <TableContainer>
                        <TableHeadingContainer>
                            <TableHeading>DOMAIN</TableHeading>
                            <TableHeading basis={40}>TOKEN</TableHeading>
                            <TableHeading $center>OWNER</TableHeading>
                            <TableHeading $center>ACTIONS</TableHeading>
                        </TableHeadingContainer>
                        <TableContentContainer>
                            {loading && <div style={{ display: "flex", justifyContent: "center", padding: "1rem" }}><LoadingSpinner /></div>}
                            {sites.sort((a) => a.userId === id ? -1 : 1).map(it =>
                                <AdminMcPasteSiteRow key={it.token} site={it} ownerName={users?.find(user => user.id === it.userId)?.username ?? it.userId} onSiteRemove={onSiteRemove} />
                            )}
                        </TableContentContainer>
                    </TableContainer>
                </TableWrapper>
            </LimitedContentContainer>
        </Container>
    );
};

export default AdminMcPasteContainer;