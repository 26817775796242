import { useFormik } from "formik";
import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";

import { useAuth } from "../../../hooks/useAuth";
import { useHttp, useTransform } from "../../../hooks/useHttp";
import FormWrapper from "../../auth/elements/AuthFormWrapper";
import InputLabel from "../../auth/elements/AuthInputLabel";
import Button from "../../elements/Button";
import CardDiv from "../../elements/CardDiv";
import LoadingSpinner from "../../elements/LoadingSpinner";
import Modal from "../../elements/Modal";
import TextInput from "../../elements/TextInput";
import { AuthenticationMethodContainer } from "./ProfileTwoFactor";

const LinkWrapper = styled.a`
    color: #408CFF;
    text-decoration: underline;
    cursor: pointer;
`;

type TotpData = {
    enabled: boolean,
    secret: string,
    authUri: string
}

const Totp = () => {
    const { setToken } = useAuth();
    
    const [ status, responseData ] = useHttp<TotpData>(axios => axios.get("/user/totp"));

    const [ data, setData ] = useState<TotpData>();

    const [ updateStatus, updateData, updateErrors, updateTransform ] = useTransform<{ enabled: boolean, newToken: string }, { otp: string }>(
        (axios, formData) => axios.post("/user/totp/change", { ...formData, enable: !data?.enabled })
    );

    const [ showTotp, setShowTotp ] = useState(false);

    useEffect(() => {
        if(status !== "done" || !responseData) return;

        setData(responseData);
    }, [status, responseData]);

    useEffect(() => {
        if(updateStatus !== "done" || !updateData) return;

        setShowTotp(false);
        setToken(updateData.newToken);
        setTimeout(() => setData(d => d ? ({ ...d, enabled: !d.enabled }) : undefined), 200);
    }, [updateStatus]);

    const formik = useFormik<{ otp: string }>({
        initialValues:{
            otp: ""
        },
        validationSchema: yup.object().shape({ otp: yup.string().required("").matches(/^\d+$/g, "Code must be numberic").length(6, "Invalid Code") }),
        validateOnChange: false,
        onSubmit: updateTransform
    });

    if(status === "loading" || !data)
        return <></>;

    return (
        <AuthenticationMethodContainer>
            <Modal title={data.enabled ? "Confirm 2FA" : "Setup 2FA"} active={showTotp} onExit={() => setShowTotp(false) }>
                {updateErrors.map((err, i) => <span key={i} style={{ color: "red" }}>{err}</span>)}
                <FormWrapper onSubmit={formik.handleSubmit}>
                    {!data.enabled && <QRCode value={data.authUri} size={192} renderAs={"svg"} />}
                    {!data.enabled && <span style={{ textAlign: "center" }}>Scan the following code with an app such as <b>Google authenticator</b> or <b>Authy</b> and enter the recieved code below.</span>}
                    <CardDiv>
                        <InputLabel text="Authenticator code" error={formik.errors.otp} />
                        <TextInput type="text" inputMode="numeric" name="otp" formik={formik} style={{ appearance: "none" }} />
                    </CardDiv>
                    <Button disabled={updateStatus === "loading"}>{data.enabled ? "Disable" : "Enable"}</Button>
                </FormWrapper>
            </Modal>
            <span>Authenticator app</span>
            <LinkWrapper onClick={() => setShowTotp(true)}>{data.enabled ? "disable" : "enable"}</LinkWrapper>
        </AuthenticationMethodContainer>
    );
};

export default Totp;