import { faCheck, faCog } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "debounce";
import { McPasteSite } from "models";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useStoreActions } from "../../../../hooks/state";

import { useTransform } from "../../../../hooks/useHttp";
import ActionButtonElement from "../../../elements/ActionButton";
import Icon from "../../../elements/Icon";

const SiteContainer = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 0.6rem;
    font-weight: 300;
    width: 360px;
    background-color: white;
    border-radius: 4px;
`;

const Title = styled.div`
    font-size: 1.3rem;
`;

const TokenContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    div:nth-child(3) {
        opacity: 0;
        transition: 100ms linear;
    }

    input:nth-child(2):hover + div:nth-child(3), div:nth-child(3):hover {
        opacity: 1;
    }
`;

const TokenLabel = styled.div`
    font-size: 0.96rem;
    margin: 0 0 0.2rem 0.2rem;
    display: flex;
    justify-content: space-between;
`;

const CopiedContainer = styled.div`
    display: flex;
    gap: 0.4rem;
    color: #5CBE77;
    font-weight: 400;
    padding: 0 0.2rem;
`;

const TokenBox = styled.input<{ $revealed: boolean }>`
    padding: 0.6rem;
    width: 100%;
    height: 40px;
    background-color: #F6F6F6;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    overflow-x: hidden;
    outline: none;
    color: #0a0a0a;
    font-weight: 300;
    font-size: 1rem;
    cursor: pointer;
    ${p => !p.$revealed && `
        color: transparent;
        text-shadow: 0 0 5px #444444;
    `}
`;

const RevealButton = styled.div`
    position: absolute;
    cursor: pointer;
    color: #408cff;
    text-decoration: underline;
    font-size: 1.1rem;
    font-weight: 500;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 0.3rem;
`;

const ActionButton = styled(ActionButtonElement)`
    font-size: 1rem;
    width: 110px;
    padding: 0.5rem;
    gap: 0.2rem;
    align-items: center;
`;

const McPasteSiteBox = ({ onSiteRemove, site }: { onSiteRemove: (site: string) => void, site: McPasteSite }) => {
    const [ copied, setCopied ] = useState(false);
    const [ revealed, setRevealed ] = useState(false);

    const activateModal = useStoreActions(state => state.modals.activateModal);

    const [ deleteStatus, ,, deleteTransform ] = useTransform((axios) => axios.delete(`/modules/mcpaste/delete/${site.id}`,));

    useEffect(() => {
        if(deleteStatus !== "done") return;

        onSiteRemove(site.site);
    }, [deleteStatus]);

    const unsetCopied = debounce(() => {
        setTimeout(() => setCopied(false), 2000);
    }, 2000, true);

    const copyToken = () => {
        navigator.clipboard.writeText(site.token);
        setCopied(true);
        
        unsetCopied();
    };

    const handleDelete = () => {
        activateModal({
            type: "confirm",
            data: {
                title: "Delete site?",
                message: "Are you sure you want to delete this site?",
                confirmButton: "Delete",
                onConfirm: deleteTransform
            }
        })
    }

    return (
        <SiteContainer>
            <Title>{site.site}</Title>
            <TokenContainer>
                <TokenLabel>
                    <span>Authorization token&#58;</span>
                    {copied &&
                        <CopiedContainer>
                            Copied
                            <Icon icon={faCheck} style={{ width: "12px", height: "auto" }} />
                        </CopiedContainer>}
                </TokenLabel>
                <TokenBox onClick={() => revealed ? copyToken() : setRevealed(true)} $revealed={revealed} type="text" value={site.token} readOnly />
                {!revealed && <RevealButton onClick={() => setRevealed(true)}>reveal</RevealButton>}
            </TokenContainer>
            <ButtonContainer>
                <Link to={`/modules/mcpaste/configure/${site.id}`} style={{ textDecoration: "none" }}>
                    <ActionButton>
                        <Icon icon={faCog} style={{ width: "14px", height: "auto" }} />
                        Configure
                    </ActionButton>
                </Link>
                <ActionButton onClick={handleDelete} disabled={deleteStatus === "loading"} $red>Delete</ActionButton>
            </ButtonContainer>
        </SiteContainer>
    );
};

export default McPasteSiteBox;