import { useMemo } from "react";
import styled from "styled-components";

import CardTitle from "./CardTitle";
import Wrapper from "./FullPageWrapper";
import LoadingSpinner from "./LoadingSpinner";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
`;

const loadingMessages = ["Starting the engines!", "Hacking the pentagon!", "DDOSing the mainframe! :P", "Boop!"];

const FullPageLoading = ({ text, funify }: { text?: string, funify?: boolean }) => {
    const loadingMessage = useMemo(() => loadingMessages[Math.floor(Math.random() * loadingMessages.length)], []);
    
    return (
        <Container>
            <Wrapper>
                <LoadingSpinner size="BIG" />
                <CardTitle>{ funify ? loadingMessage : text }</CardTitle>
            </Wrapper>
        </Container>
    );
};

export default FullPageLoading;