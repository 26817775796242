import GooglePayButton from "@google-pay/button-react";
import { BraintreePayPalButtons, CreateBillingAgreementActions, CreateOrderBraintreeActions, FUNDING, OnApproveBraintreeActions, OnApproveBraintreeData } from "@paypal/react-paypal-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import { useState } from "react";
import { FC } from "react";
import styled from "styled-components";

import CardDivider from "./CardDivider";
import CardDividerContainer from "./CardDividerContainer";

const PaymentContainer = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;
    padding: 1rem;
`;

const CardEntryContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 4px;
`;

const CardElementContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    padding: 1.2rem;
    background-color: #f6f6f6;
    border-radius: 4px;
`;

const CardOtherInfo = styled.div`
    display: flex;
    
    & .StripeElement {
        flex-basis: 50%;
    }
`;

export const PaymentButton = styled.button`
    border: none;
    color: #f6f6f6;
    padding: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
    transition: 100ms linear;
    background-color: #0a0a0a;
    border-radius: 4px;
    gap: 0.2rem;
    cursor: pointer;

    &:hover {
        background-color: #2c2c2c;
        transition: 100ms linear;
    }

    &[disabled] {
        background-color: #313131;
        cursor: default;
    }
`;

const WideGooglePayButton = styled(GooglePayButton)`
    & button {
        width: 100% !important;
    }
`;

type PaypalProps = {
    onApprove: (data: OnApproveBraintreeData, actions: OnApproveBraintreeActions) => Promise<void>
} & ({
    createOrder: (actions: CreateOrderBraintreeActions) => Promise<string>
} | {
    createBillingAgreement: (actions: CreateBillingAgreementActions) => Promise<string>
})

type GooglePayTransactionInfo = {
    label: string,
    price: number
}

type Props = {
    buttonText: string
    disabled?: boolean,
    onStripeSubmit?: (e: StripeCardNumberElement) => void,
    paypalButtonLabel: 
        | "paypal"
        | "checkout"
        | "buynow"
        | "pay"
        | "installment"
        | "subscribe"
        | "donate"
    paypal?: PaypalProps;
} & ({
    includeGooglePay: true,
    googlePayTransactionInfo: GooglePayTransactionInfo,
    googlePayButtonLabel: google.payments.api.ButtonType,
    onGoogleSubmit: (data: google.payments.api.PaymentData) => void
} | {
    includeGooglePay: false
});

const PaymentForm: FC<Props> = (props) => {
    const { buttonText, disabled, onStripeSubmit, paypalButtonLabel, paypal } = props;

    const [ stripeCardElement, setStripeCardElement ] = useState<StripeCardNumberElement>();

    const onNumberElementReady = (el: StripeCardNumberElement) => {
        setStripeCardElement(el);
    };

    return (
        <PaymentContainer>
            {process.env.DISABLE_STRIPE !== "true" &&
                <CardEntryContainer>
                    <CardElementContainer>
                        <CardNumberElement onReady={onNumberElementReady} options={{
                            showIcon: true
                        }} />
                        <CardOtherInfo>
                            <CardExpiryElement />
                            <CardCvcElement />
                        </CardOtherInfo>
                    </CardElementContainer>
                    <PaymentButton disabled={disabled || !stripeCardElement} onClick={() => onStripeSubmit && onStripeSubmit(stripeCardElement!)}>
                        {buttonText}
                    </PaymentButton>
                </CardEntryContainer>
            }
            {process.env.DISABLE_BRAINTREE !== "true" &&
            <>
                <CardDividerContainer>
                    <CardDivider />
                    <span>or</span>
                    <CardDivider />
                </CardDividerContainer>
                <div>
                    <BraintreePayPalButtons
                        style={{
                            color: "blue",
                            label: paypalButtonLabel,
                            height: 38
                        }}
                        onApprove={paypal?.onApprove}
                        fundingSource={FUNDING["PAYPAL"]}
                        {...(paypal && ("createOrder" in paypal ?
                            {
                                createOrder: (_, actions) => paypal.createOrder(actions)
                            } : {
                                createBillingAgreement: (_, actions) => paypal.createBillingAgreement(actions)
                            }
                        ))}
                    />
                </div>
            </>
            }
            {(props.includeGooglePay && process.env.DISABLE_STRIPE !== "true") &&
                <>
                    <CardDividerContainer>
                        <CardDivider />
                        <span>or</span>
                        <CardDivider />
                    </CardDividerContainer>
                    <WideGooglePayButton
                        environment={process.env.PAYMENT_MODE === "production" ? "PRODUCTION" : "TEST"}
                        buttonType={props.googlePayButtonLabel}
                        paymentRequest={{
                            apiVersion: 2,
                            apiVersionMinor: 0,
                            allowedPaymentMethods: [
                                {
                                    type: "CARD",
                                    parameters: {
                                        allowedCardNetworks: ["VISA", "MASTERCARD", "JCB", "DISCOVER", "AMEX"],
                                        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"]
                                    },
                                    tokenizationSpecification: {
                                        type: "PAYMENT_GATEWAY",
                                        parameters: {
                                            gateway: "stripe",
                                            "stripe:version": "2020-08-27",
                                            "stripe:publishableKey": process.env.STRIPE_PUBLISHABLE_KEY!
                                        }
                                    }
                                }
                            ],
                            merchantInfo: {
                                merchantId: "12345678901234567890",
                                merchantName: "Demo Merchant"
                            },
                            transactionInfo: {
                                totalPriceStatus: "FINAL",
                                totalPriceLabel: props.googlePayTransactionInfo.label,
                                totalPrice: props.googlePayTransactionInfo.price.toFixed(2),
                                currencyCode: "USD",
                                countryCode: "US"
                            }
                        }}
                        onLoadPaymentData={props.onGoogleSubmit}
                    />
                </>
            }
        </PaymentContainer>
    );
};

export default PaymentForm;