import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faAddressBook,
    faBusinessTime,
    faChartLine,
    faCodeBranch,
    faLink,
    faMicrochip,
    faMoneyBillWave,
    faPaste,
    faSignOutAlt,
    faTachometerAlt,
    faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FC, useState } from "react";
import { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import HM4Logo from "url:/assets/favicon.ico";

import { useStoreActions, useStoreState } from "../../hooks/state";
import { useAuth } from "../../hooks/useAuth";
import Button from "../elements/Button";
import CardDivider from "../elements/CardDivider";
import CardDividerContainer from "../elements/CardDividerContainer";
import Copyright from "../elements/Copyright";
import Icon from "../elements/Icon";
import IconButton from "../elements/IconButton";

const NavbarContainer = styled.div`
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    display: flex;
    color: rgb(10, 10, 10);
`;

const Navbar = styled.div<{ $active: boolean }>`
    width: 256px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
    background-color: #fefefe;
    color: rgb(10, 10, 10);
    flex-shrink: 0;
    transition: 200ms ease-in;

    @media (max-width: 768px) {
        ${(p) => p.$active && "width: 50%"};
        margin-right: ${(p) => (p.$active ? "0" : "-256")}px;
        transition: 200ms ease-out;
    }

    @media (max-width: 480px) {
        ${(p) => p.$active && "width: 100%"};
    }
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    margin: 1rem 0;
    margin-right: 2rem; // moves it to the left a bit
`;

const LogoTitle = styled.div`
    font-weight: 800;
    font-size: 2rem;
    padding-top: 0.6rem;
`;

const NavbarLinkContainer = styled(Link)<{ $active: boolean }>`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: rgb(10, 10, 10);
    height: 3.6rem;
    background-color: ${(p) => (p.$active ? "#E7E7E7" : "#F3F3F3")};
    font-size: 1.1rem;
    font-weight: 300;
    padding: 1.4rem;
    gap: 0.6rem;
    transition: 200ms linear;

    &:hover {
        background-color: #e7e7e7;
        transition: 200ms linear;
    }

    &:hover div:first-child {
        width: 7px;
        transition: 200ms linear;
    }
`;

const NavbarLinkHighlight = styled.div<{ $active: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(p) => (p.$active ? "7px" : "0")};
    background-color: #408cff;
    transition: 200ms linear;
`;

const NavbarIcon = styled(Icon)`
    flex-basis: 2rem;
`;

const NavbarLink = ({
    text,
    to,
    icon,
    extendable = false,
}: {
    text: string;
    to: string;
    icon: IconProp;
    extendable?: boolean;
}) => {
    const location = useLocation();

    const [active, setActive] = useState(false);

    useEffect(() => {
        setActive(
            extendable
                ? location.pathname.startsWith(to)
                : (to !== "/" &&
                      location.pathname.split("/").length === to.split("/").length &&
                      location.pathname.startsWith(to)) ||
                      location.pathname === to,
        );
    }, [location]);

    return (
        <NavbarLinkContainer
            to={to}
            $active={active}
            onClick={() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            }}
        >
            <NavbarLinkHighlight $active={active} />
            <NavbarIcon icon={icon} />
            {text}
        </NavbarLinkContainer>
    );
};

const SectionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SectionDivider = ({ text }: { text: string }) => {
    return (
        <CardDividerContainer
            style={{ marginTop: "1rem", marginBottom: "0.6rem", color: "rgba(0, 0, 0, 0.8)" }}
            width="90%"
        >
            <CardDivider />
            {text}
            <CardDivider />
        </CardDividerContainer>
    );
};

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: linear-gradient(252deg, #dfdfdf 4.28%, #c4c4c4 100%);
    flex-grow: 1;
    z-index: 0;
    width: calc(100% - 256px);

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Topbar = styled.div`
    background-color: #3370ca;
    height: 3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    padding: 0 1rem;
`;

const TopbarWelcome = styled.div`
    display: flex;
    gap: 0.4rem;

    span:first-child {
        font-weight: 300;
    }

    span:nth-child(2) {
        font-weight: 500;
    }
`;

const TopbarMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1.6rem;
    height: 1.4rem;
    cursor: pointer;
    z-index: -1;

    span {
        width: 100%;
        height: 3px;
        border-radius: 1px;
        background-color: #ebebeb;
    }

    @media (max-width: 768px) {
        z-index: 1;
    }
`;

const Footer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 1rem;
`;

const GitHashContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.8);
    cursor: default;
`;

const GitHash = styled.div`
    display: flex;
    gap: 0.2rem;
    align-items: center;
`;

const Navigation: FC = ({ children }) => {
    const { setToken } = useAuth();
    const history = useHistory();

    const user = useStoreState((store) => store.user.data!);
    const setUser = useStoreActions((store) => store.user.setUserData);

    const [active, setActive] = useState(false);

    const logout = () => {
        setToken("");
        setUser(undefined);
        history.push("/");
    };

    return (
        <NavbarContainer id="modal-container">
            <Navbar $active={active}>
                <LogoContainer>
                    <img style={{ height: "4rem" }} src={HM4Logo} />
                    <LogoTitle>HM4</LogoTitle>
                </LogoContainer>
                {active && (
                    <Button
                        width="10rem"
                        style={{ margin: "0 0 1rem 0" }}
                        onClick={() => {
                            setActive(false);
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                            });
                        }}
                    >
                        Close
                    </Button>
                )}
                <NavbarLink text="Dashboard" icon={faTachometerAlt} to="/" />

                <SectionContainer>
                    <SectionDivider text="Services" />
                    <NavbarLink text="Redirect" icon={faLink} to="/modules/redirect" />
                    <NavbarLink text="MCPaste" icon={faPaste} to="/modules/mcpaste" extendable />
                </SectionContainer>
                {user.admin && (
                    <SectionContainer>
                        <SectionDivider text="Admistrative" />
                        <NavbarLink text="Users" icon={faAddressBook} to="/admin/users" />
                        <NavbarLink
                            text="Gamepanel"
                            icon={faMicrochip}
                            to="/admin/panels"
                            extendable
                        />
                        <NavbarLink text="Statistics" icon={faChartLine} to="/admin/statistics" />
                        <NavbarLink text="Cron" icon={faBusinessTime} to="/admin/cron" />
                    </SectionContainer>
                )}
                <SectionContainer>
                    <SectionDivider text="Account" />
                    <NavbarLink text="Profile" icon={faUserCircle} to="/account" />
                    <NavbarLink
                        text="Billing"
                        icon={faMoneyBillWave}
                        to="/account/billing"
                        extendable
                    />
                </SectionContainer>
                <CardDividerContainer width="90%" style={{ margin: "0.5rem 0" }}>
                    <CardDivider />
                </CardDividerContainer>
                <IconButton width="14rem" onClick={logout} icon={faSignOutAlt}>
                    LOG OUT
                </IconButton>
                <GitHashContainer>
                    <GitHash>
                        <Icon icon={faCodeBranch} size={"sm"} />
                        {process.env.GIT_SHA}
                    </GitHash>
                </GitHashContainer>
            </Navbar>
            <ContentContainer>
                <Topbar>
                    <TopbarMenu onClick={() => setActive(true)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </TopbarMenu>
                    <TopbarWelcome>
                        <span>Welcome</span>
                        <span>{user.username}</span>
                    </TopbarWelcome>
                    <span></span>
                </Topbar>
                {children}
                <Footer>
                    <Copyright />
                </Footer>
            </ContentContainer>
        </NavbarContainer>
    );
};

export default Navigation;
