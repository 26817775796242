import styled from "styled-components";

const LimitedContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 4px;
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
`;

export default LimitedContentContainer;