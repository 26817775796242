import { CSSProperties, FC } from "react";
import CSSTransition, { CSSTransitionProps } from "react-transition-group/CSSTransition";
import styled from "styled-components";

type Props = Omit<CSSTransitionProps, "timeout" | "classNames"> & {
    timeout: number;
    parentStyles?: CSSProperties;
}

const Container = styled.div<{ timeout: number}>`
    .fade-enter, .fade-exit, .fade-appear {
        will-change: opacity;
    }

    .fade-enter, .fade-appear {
        opacity: 0;
    }

    .fade-enter-active, .fade-appear-active {
        opacity: 1;
        transition: opacity ${p => p.timeout}ms linear;
    }

    .fade-exit {
        opacity: 1;
    }

    .fade-exit-active {
        opacity: 0;
        transition: opacity ${p => p.timeout}ms linear;
    }
`;

const Fade: FC<Props> = ({ timeout, children, parentStyles = {}, ...props }) => {
    return (
        <Container style={parentStyles} timeout={timeout}>
            <CSSTransition timeout={timeout} classNames={"fade"} {...props} style={{ zIndex: 20 }}>
                {children}
            </CSSTransition>
        </Container>
    );
};

export default Fade;