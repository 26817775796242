import chalk from "chalk";

const Levels = {
    INFO: chalk.bgBlack.blueBright` INFO `,
    WARN: chalk.bgBlack.yellowBright` WARN `,
    DEBUG: chalk.bgBlack.yellow` DEBUG `,
    ERROR: chalk.bgBlack.redBright` ERROR `,
};

type Level = keyof typeof Levels 

const time = (): string => {
    const date = new Date();
    const h = date.getHours().toString().padStart(2, "0");
    const min = date.getMinutes().toString().padStart(2, "0");
    const s = date.getSeconds().toString().padStart(2, "0");
    return `${h}:${min}:${s}`;
};

export function log(level: Level, message = ""): void  {
    if(level !== "DEBUG" || (level === "DEBUG" && process.env.DEBUG === "true"))
        console.log(`${time()} ${Levels[level]} ${message}`);
}