import { PasswordlessUser } from "models";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { useHttp } from "../../../hooks/useHttp";
import { withNav } from "../../../hooks/withNavbar";
import LimitedContentContainer from "../../elements/LimitedContentContainer";
import LoadingSpinner from "../../elements/LoadingSpinner";
import TableContainer from "../../elements/TableContainer";
import TableContentContainer from "../../elements/TableContentContainer";
import TableHeading from "../../elements/TableHeading";
import TableHeadingContainer from "../../elements/TableHeadingContainer";
import TableWrapper from "../../elements/TableWrapper";
import AdminUserContainer from "./AdminUserContainer";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
`;

const Users = () => {
    const [ status, usersData ] = useHttp<PasswordlessUser[]>((axios) => axios.get("/admin/users"));

    const [ users, setUsers ] = useState<PasswordlessUser[]>([]);

    useEffect(() => {
        if(status !== "done" || !usersData) return;

        setUsers(usersData);
    }, [status, usersData]);

    return (
        <Container>
            <LimitedContentContainer>
                <TableWrapper>
                    <TableContainer style={{ minWidth: "1200px" }}>
                        <TableHeadingContainer>
                            <TableHeading basis={19}>ID</TableHeading>
                            <TableHeading basis={23}>USER</TableHeading>
                            <TableHeading basis={19}>DISCORD ID</TableHeading>
                            <TableHeading basis={13} $center>ATRIBUTTES</TableHeading>
                            <TableHeading basis={13} $center>CREATED</TableHeading>
                            <TableHeading basis={13} $center>ACTIONS</TableHeading>
                        </TableHeadingContainer>
                        <TableContentContainer>
                            {status === "loading" && <div style={{ display: "flex", justifyContent: "center", padding: "1rem" }}><LoadingSpinner /></div>}
                            {users.map(it => <AdminUserContainer key={it.id} user={it} />)}
                        </TableContentContainer>
                    </TableContainer>
                </TableWrapper>
            </LimitedContentContainer>
        </Container>
    );
};

export default withNav(Users);