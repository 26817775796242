import styled from "styled-components";

import TextInput from "./TextInput";

const TableInput = styled(TextInput)`
    height: 40px;
    flex-basis: 80%;
    flex-shrink: 0;
`;

export default TableInput;