import { useEffect, useState } from "react";
import { io,Socket } from "socket.io-client";

import { useStoreState } from "./state";

export const useSocket = (path: string) => {
    const [ socket, setSocket ] = useState<Socket | null>(null);
    const token = useStoreState(state => state.auth.token);

    useEffect(() => {
        const connectedSocket = io(process.env.BASE_URL!, {
            path,
            auth: {
                token
            }
        });
        setSocket(connectedSocket);

        connectedSocket.on("connect-error", () => {
            console.log("[SOCKET] Connection error! Check your authorization token");
        });

        connectedSocket.on("connect", () => {
            console.log("[SOCKET] Connected");
        });

        connectedSocket.on("disconnect", () => {
            console.log("[SOCKET] Disconnected");
        });

        return () => {
            console.log("[SOCKET] Closing connection");
            connectedSocket.close();
        };
    }, [setSocket]);

    return socket;
};