import { decode } from "base64-arraybuffer";

type ReplaceKeys<T, Keys extends string, Ignore extends string, N> = {
    [K in keyof T]:
        K extends Ignore ?
        T[K] :
            K extends Keys ?
            N :
                T[K] extends object ? ReplaceKeys<T[K], Keys, Ignore, N> :
                T[K]
};

type Base64Replace<T, K extends string, I extends string> = ReplaceKeys<T, K, I, ArrayBuffer>;

export const decodeFieldsToArrayBuffer = <T, K extends string, I extends string>(obj: T, fields: K[], ignore: I[] = []): Base64Replace<T, K, I> => {
    const modified: any = { ...obj };
    for(const [ k, v ] of Object.entries(modified)) {
        if(ignore.includes(k as I) || Array.isArray(v)) continue;
        if(typeof v === "string" && fields.includes(k as K))
            modified[k] = decode(v);
        else if(typeof v === "object")
            modified[k] = decodeFieldsToArrayBuffer(v, fields, ignore);
    }
    return modified;
};