import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useQueryMessages } from "../../../hooks/useQueryErrors";
import { withNav } from "../../../hooks/withNavbar";
import ProfileEdit from "./ProfileEdit";
import ProfileInfo from "./ProfileInfo";
import ProfileTwoFactor from "./ProfileTwoFactor";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
`;

const Profile = () => {
    const history = useHistory();
    const { queryErrors } = useQueryMessages();

    useEffect(() => {
        history.replace("/account");
    }, [queryErrors]);
    
    return (
        <Container>
            {queryErrors.map(it => <span key={it} style={{ color: "red" }}>{it}</span>)}
            <ProfileInfo />
            <ProfileTwoFactor />
            <ProfileEdit />
        </Container>
    );
};

export default withNav(Profile);