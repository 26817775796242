import path from 'path';
import braintree from 'braintree';
import { log } from 'logging';
import stripe from 'stripe'
import { config as dotenvConfig } from 'dotenv';
dotenvConfig({ path: path.resolve(process.cwd(), "..", "..", "libs", "payments", ".env") });

export const createDefaultStripe = () => {
    log("INFO", "Initializing Stripe API");
    return new stripe(process.env.STRIPE_SECRET_KEY, {
        apiVersion: "2020-08-27",
        typescript: true
    });
}

export const createDefaultBraintree = () => {
    const environment = process.env.PAYMENT_MODE.toLowerCase() !== "production" ? braintree.Environment.Sandbox : braintree.Environment.Production;
    log("INFO", `Initializing Braintree API with ${(environment as typeof environment & { server: string }).server}`);
    return new braintree.BraintreeGateway({
        environment,
        merchantId: process.env.BRAINTREE_MERCHANT_ID,
        publicKey: process.env.BRAINTREE_PUBLIC_KEY,
        privateKey: process.env.BRAINTREE_PRIVATE_KEY
    })
}