import { Action, action } from "easy-peasy";

type ModalData = {
    active: boolean
    title: string,
    message: string,
    confirmButton: string,
    onConfirm: Function,
    onDecline: Function
}

const defaultModalData: ModalData = {
    active: false,
    title: "",
    message: "",
    confirmButton: "",
    onConfirm: () => {},
    onDecline: () => {}
}

type AllBut<T, E> = { [k in keyof T as T[k] extends E ? never : k]: T[k] }

export type ModalStore = {
    confirm: ModalData,
    activateModal: Action<ModalStore, { type: keyof AllBut<ModalStore, Action<any, any>>, data: Partial<ModalData> }>,
    deactivateModal: Action<ModalStore, { type: keyof AllBut<ModalStore, Action<any, any>> }>
}

export const modals: ModalStore = {
    confirm: defaultModalData,
    activateModal: action((state, payload) => {
        state[payload.type] = {
            ...state[payload.type],
            ...payload.data,
            active: true
        }
    }),
    deactivateModal: action((state, payload) => {
        state[payload.type] = {
            ...state[payload.type],
            active: false
        }
    })
};