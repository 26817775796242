import { Link } from "react-router-dom";

import Button from "./Button";

const LinkButton = ({ to, text, width, disabled }: { to: string, text: string, width?: string, disabled?: boolean }) => {
    return (
        <Link to={to} style={{ textDecoration: "none", color: "white", width: width ?? "100%" }}>
            <Button width={width} disabled={disabled}>
                { text }
            </Button>
        </Link>
    );
};

export default LinkButton;