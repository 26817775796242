import { FC, ReactElement } from "react";
import styled, { keyframes } from "styled-components";
import HM4Logo from "url:/assets/favicon.ico";

const Spin = keyframes`
    to {
        transform: rotate(1turn);
    }
    0% {
        opacity: 0.8;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.8;
    }
`;

const Blink = keyframes`
    0% {
        opacity: 0.8;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.8;
    }
`;

const SpinnerContainer = styled.div<{ size: string }>`
    position: relative;
    border-radius: 50%;
    height: ${p => p.size};
    width: ${p => p.size};
`;

const SpinnerPart = styled.div<{ size: string }>`
    border-bottom: 5px solid #1a5abb;
    border-radius: 50%;
    width: ${p => p.size};
    height: ${p => p.size};
    animation: ${Spin} 1s linear infinite;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const SpinnerLogoContainer = styled.div<{ size: string }>`
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%);
        width: calc(${p => p.size} - 25px);
        height: auto;
        animation: ${Blink} 1s linear infinite;
    }
`;

export const SpinnerSizes = {
    SMALL: "40px",
    NORMAL: "70px",
    BIG: "100px",
    LARGE: "150px"
};

const LoadingSpinner: FC<{ size?: keyof typeof SpinnerSizes } & ({ img: ReactElement<HTMLImageElement>, disabled?: ReactElement<HTMLImageElement>, show: boolean } | {})> = ({ size = "NORMAL", ...props }) => {
    if("img" in props && !props.show)
        return props.disabled ?? props.img;

    return (
        <SpinnerContainer size={SpinnerSizes[size]}>
            <SpinnerPart size={SpinnerSizes[size]} />
            {size !== "SMALL" && <SpinnerLogoContainer size={SpinnerSizes[size]}>{"img" in props ? props.img : <img src={HM4Logo} />}</SpinnerLogoContainer> }
        </SpinnerContainer>
    );
};

export default LoadingSpinner;