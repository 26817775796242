import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import { User } from "models";
import { useEffect } from "react";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import yubikeyLogo from "url:/assets/yubikey.png";
import * as yup from "yup";

import { useStoreActions } from "../../hooks/state";
import { useAuth } from "../../hooks/useAuth";
import { clearRequestCache, doRequest, useTransform } from "../../hooks/useHttp";
import { useQueryMessages } from "../../hooks/useQueryErrors";
import { withCaptcha } from "../../hooks/withCaptcha";
import { decodeAssertion, encodeAssertResponse, TransmittableAssertCredential } from "../../lib/webauthn";
import ALink from "../elements/ALink";
import Button from "../elements/Button";
import Card from "../elements/Card";
import CardDiv from "../elements/CardDiv";
import CardDivider from "../elements/CardDivider";
import CardDividerContainer from "../elements/CardDividerContainer";
import CardText from "../elements/CardText";
import CardTitle from "../elements/CardTitle";
import Copyright from "../elements/Copyright";
import DiscordButton from "../elements/DiscordButton";
import FullPageLoading from "../elements/FullPageLoading";
import Wrapper from "../elements/FullPageWrapper";
import IconButton from "../elements/IconButton";
import LoadingSpinner from "../elements/LoadingSpinner";
import Modal from "../elements/Modal";
import TextInput from "../elements/TextInput";
import FormWrapper from "./elements/AuthFormWrapper";
import InputLabel from "./elements/AuthInputLabel";

const LoginSchema = yup.object().shape({
    email: yup.string().email("Invalid Email!").required("Email is a required field!"),
    password: yup.string().required("Password is a required field!")
});

type FormValues = {
    email: string,
    password: string
}

type LoginValues = FormValues & { token: string }

type LoginResponse = { token: string } | { availableTwoFactor: ("totp" | "webauthn")[], session: string }

const LinkWrapper = styled(Link)`
    color: #408CFF;
    text-decoration: underline;
`;

const LinkText = styled.div`
    cursor: pointer;
    color: #408CFF;
    text-decoration: underline;
    font-size: 1rem;
    margin-top: 0.2rem;
`;

const LoadingOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;

const randRange = (min: number, max: number) => Math.floor((Math.random() * (max - min)) + min);

const Login = () => {
    const { valid, token, setToken } = useAuth();

    const history = useHistory();

    const { queryErrors, queryMessages } = useQueryMessages();

    useEffect(() => {
        history.replace("/");
        formik.setFieldError("passedError", queryErrors.join(";"));
    }, [queryMessages, queryErrors]);

    const setUser = useStoreActions(store => store.user.setUserData);

    const [ status, data, errors, transform ] = useTransform<LoginResponse, LoginValues>(
        (axios, credentials) => axios.post("/auth/login", { ...credentials, scope: "ACCOUNT" }),
        { autoProccessErrors: false }
    );

    const [ showResend, setShowResend ] = useState(false);
    const [ showForgotPassword, setShowForgotPassword ] = useState(false);
    const [ showTotp, setShowTotp ] = useState(false);
    const [ showWebauthn, setShowWebauthn ] = useState(false);
    const [ session, setSession ] = useState<string>();
    const [ availableTwoFactor, setAvailableTwoFactor ] = useState<("totp" | "webauthn")[]>([]);

    const [ webauthnErrors, setWebauthnErrors ] = useState<string[]>([]);

    const [ resendStatus,,, resendTransform ] = useTransform<unknown, LoginValues>(
        (axios, credentials) => axios.post("/auth/resend-verification", { ...credentials, scope: "ACCOUNT" }),
        { autoProccessErrors: false }
    );
    
    const [ totpStatus, totpData, totpErrors, totpTransform ] = useTransform<{ token: string }, { otp: string, token: string }>(
        (axios, data) => axios.post("/auth/factor/totp", { session: session, ...data }),
        { autoProccessErrors: false }
    );
    
    const [ assertStatus, assertData, assertErrors, assertTransform ] = useTransform<{ token: string }, TransmittableAssertCredential>(
        (axios, credential) => axios.post("/auth/factor/webauthn/assert", { session: session, credential }),
        { autoProccessErrors: false }
    );

    const [ passwordStatus,, passwordErrors, passwordTransform ] = useTransform<unknown, { email: string, token: string }>(
        (axios, data) => axios.post("/auth/forgot-password", data),
        { autoProccessErrors: false }
    );

    const [ emailSent, setEmailSent ] = useState(false);
    const [ passwordResetSent, setPasswordResetSent ] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const [ captchaLoading, setCaptchaLoading ] = useState(false);

    const onSubmit = async <T,>(data: T, transform: (data: T & { token: string }) => void) => {
        setCaptchaLoading(true);
        if(!executeRecaptcha) {
            formik.setFieldError("captchaError", "Captcha failed to load, try refreshing the page.");
            setCaptchaLoading(false);
            return;
        }
        const token = await executeRecaptcha().finally(() => setCaptchaLoading(false));
        transform({ ...data, token });
    };

    useEffect(() => {
        setEmailSent(resendStatus === "done");
    }, [resendStatus]);

    useEffect(() => {
        if(status !== "done" && status !== "errored") return;
        if(status === "errored") {
            if(errors.some(error => error.includes("verified")))
                setShowResend(true);
            return formik.setFieldError("serverError", errors.join("; "));
        }
        
        if(!data) return;
        if("token" in data)
            return setToken(data.token);

        setSession(data.session);
        setAvailableTwoFactor(data.availableTwoFactor);
        if(data.availableTwoFactor.includes("webauthn"))
            setShowWebauthn(true);
        else if(data.availableTwoFactor.includes("totp"))
            setShowTotp(true);
    }, [status]);

    useEffect(() => {
        if(totpStatus !== "done" || !totpData) return;
        setShowTotp(false);
        setToken(totpData.token);
    }, [totpStatus]);

    useEffect(() => {
        if(!showWebauthn) return;

        setWebauthnErrors([]);
        (async () => {
            const [ data, errors ] = await doRequest<{ assertion: PublicKeyCredentialRequestOptions }>(axios => axios.post("/auth/factor/webauthn/opts", { session }));
            if(errors.length)
                return setWebauthnErrors(errors);
            if(!data) return;
            const assertion = decodeAssertion(data.assertion);
            let res: Credential;
            try {
                const createRes = await navigator.credentials.get({ publicKey: assertion });
                if(!createRes) throw new Error();
                res = createRes;
            } catch {
                return setWebauthnErrors(["Failed to authenticate"]);
            }
    
            const credential = encodeAssertResponse(res as PublicKeyCredential);
            assertTransform(credential);
        })();
    }, [showWebauthn]);

    useEffect(() => {
        if(assertStatus !== "done" || !assertData) return;

        setShowWebauthn(false);
        setToken(assertData.token);
    }, [assertStatus]);

    useEffect(() => {
        setWebauthnErrors(assertErrors);
    }, [assertErrors]);

    useEffect(() => {
        clearRequestCache();
        doRequest<User>(axios => axios.get("/user/info"))
            .then(([ user ]) => setUser(user));
    }, [token]);

    useEffect(() => {
        setPasswordResetSent(passwordStatus === "done");
        if(passwordStatus === "errored")
            forgotPasswordFormik.setFieldError("serverError", passwordErrors.join("; "));
    }, [passwordStatus]);

    const formik = useFormik<FormValues>({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: LoginSchema,
        validateOnChange: false,
        onSubmit: (creds) => onSubmit(creds, transform),
    });

    const totpFormik = useFormik<{ otp: string }>({
        initialValues:{
            otp: ""
        },
        validationSchema: yup.object().shape({ otp: yup.string().required("").matches(/^\d+$/g, "Code must be numberic").length(6, "Invalid Code") }),
        validateOnChange: false,
        onSubmit: (data) => onSubmit(data, totpTransform)
    });

    const forgotPasswordFormik = useFormik<{ email: string }>({
        initialValues:{
            email: ""
        },
        validationSchema: yup.object().shape({ email: yup.string().email("Invalid Email!").required("Email is a required field!") }),
        validateOnChange: false,
        onSubmit: (data) => onSubmit(data, passwordTransform)
    });

    return (
        <>
            {valid && !formik.dirty ? <FullPageLoading funify /> :
                <Wrapper>
                    <Modal title="User verification needed!" active={showResend} onExit={() => {
                        setShowResend(false);
                        setEmailSent(false);
                    }}>
                        <CardText>Your account is not verified yet!</CardText>
                        {emailSent && <CardText style={{ color: "green" }}>Email sent!</CardText>}
                        <Button onClick={() => onSubmit(formik.values, resendTransform)} disabled={captchaLoading || resendStatus === "loading" || emailSent}>Resend verification email</Button>
                    </Modal>
                    <Modal title="2 Factor Authentication" active={showTotp} onExit={() => setShowTotp(false) }>
                        {totpErrors.map((err, i) => <span key={i} style={{ color: "red" }}>{err}</span>)}
                        <FormWrapper onSubmit={totpFormik.handleSubmit}>
                            <CardDiv>
                                <InputLabel text="Authenticator code" error={totpFormik.errors.otp} />
                                <TextInput type="text" inputMode="numeric" name="otp" placeholder={(randRange(1e5, 9e5)).toString()} formik={totpFormik} style={{ appearance: "none" }} />
                            </CardDiv>
                            <Button disabled={captchaLoading || totpStatus === "loading"}>Submit</Button>
                        </FormWrapper>
                        {availableTwoFactor.includes("webauthn") && <LinkText onClick={() => { setShowWebauthn(true); setShowTotp(false); }}>use a security key</LinkText>}
                    </Modal>
                    <Modal title="2 Factor Authentication" active={showWebauthn} onExit={() => setShowWebauthn(false) }>
                        {webauthnErrors.map((err, i) => <span key={i} style={{ color: "red" }}>{err}</span>)}

                        <LoadingSpinner
                            size="LARGE"
                            show={!webauthnErrors.length}
                            img={<img src={yubikeyLogo} style={{ width: "42px", transform: "translate(-50%, -50%) rotate(60deg)", transformOrigin: "center" }}/>}
                            disabled={<img src={yubikeyLogo} style={{ width: "42px", transform: "rotate(60deg)" }}/>}
                        />
                        
                        {!webauthnErrors.length && <span style={{ textAlign: "center" }}>Insert your security key</span> }
                        {availableTwoFactor.includes("totp") && <LinkText onClick={() => { setShowTotp(true); setShowWebauthn(false); }}>use the authenticator app</LinkText>}
                    </Modal>
                    <Modal title="Forgot password" active={showForgotPassword} onExit={() => {
                        setShowForgotPassword(false);
                        setPasswordResetSent(false);
                    }}>
                        {Object.entries(forgotPasswordFormik.errors).filter(([k]) => !Object.keys(formik.values).includes(k)).map(([k, v]) => <span key={k} style={{ color: "red" }}>{v}</span>)}
                        {passwordResetSent && <CardText style={{ color: "green" }}>Check your email for password reset instructions!</CardText>}
                        <FormWrapper onSubmit={forgotPasswordFormik.handleSubmit}>
                            <CardDiv>
                                <InputLabel text="Email" error={forgotPasswordFormik.errors.email} />
                                <TextInput type="email" placeholder="some@example.com" name="email" formik={forgotPasswordFormik} />
                            </CardDiv>
                            <Button disabled={captchaLoading || passwordResetSent || passwordStatus === "loading"}>Reset password</Button>
                        </FormWrapper>
                    </Modal>
                    <Card>
                        {(valid) &&
                            <LoadingOverlay>
                                <LoadingSpinner />
                            </LoadingOverlay>
                        }
                        <CardTitle style={{ marginBottom: "1rem" }}>Login</CardTitle>
                        {!!queryMessages.length && queryMessages.map((it, i) => <span key={i} style={{ color: "green" }} >{it}</span>)}
                        {Object.entries(formik.errors).filter(([k, v]) => !Object.keys(formik.values).includes(k) && !v.includes("User not verified") && v).map(([k, v]) => <span key={k} style={{ color: "red" }}>{v}</span>)}
                        <FormWrapper onSubmit={formik.handleSubmit}>
                            <CardDiv>
                                <InputLabel text="Email" error={formik.errors.email} />
                                <TextInput type="text" placeholder="some@example.com" name="email" formik={formik} />
                            </CardDiv>
                            <CardDiv>
                                <InputLabel text="Password" error={formik.errors.password} />
                                <TextInput type="password" placeholder="******" name="password" formik={formik} />
                                <LinkText onClick={() => setShowForgotPassword(true)}>forgot password?</LinkText>
                            </CardDiv>
                            <IconButton disabled={captchaLoading || status === "loading" || valid} style={{ justifyContent: "space-between" }} icon={faArrowRight} >LOG IN</IconButton>
                        </FormWrapper>
                        <CardDividerContainer>
                            <CardDivider />
                                or
                            <CardDivider />
                        </CardDividerContainer>
                        <ALink href={process.env.DISCORD_LOGIN_URL}>
                            <DiscordButton text={"Login with Discord"} />
                        </ALink>
                        <CardText>
                            don&apos;t have an account yet?
                            <LinkWrapper to="/register">
                                register
                            </LinkWrapper>
                        </CardText>
                    </Card>
                    <Copyright />
                </Wrapper>
            }
        </>
    );
};

export default withCaptcha(Login);