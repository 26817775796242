import { PasswordlessUser } from "models";
import styled from "styled-components";
import { useStoreState, useStoreActions } from "../../../hooks/state";
import { formatDateTime } from "../../../lib/date";
import ActionButton from "../../elements/ActionButton";
import SectionContainer from "../../elements/SectionContainer";
import SectionHeader from "../../elements/SectionHeader";
import TableContainer from "../../elements/TableContainer";
import TableContentContainer from "../../elements/TableContentContainer";
import TableEntry from "../../elements/TableEntry";
import TableHeading from "../../elements/TableHeading";
import TableHeadingContainer from "../../elements/TableHeadingContainer";
import TableRowContainer from "../../elements/TableRowContainer";
import TableWrapper from "../../elements/TableWrapper";
import DiscordLogo from "url:/assets/discord.svg";
import React, { FC, useEffect } from "react";
import { useTransform } from "../../../hooks/useHttp";

const Centered = styled.div`
    text-align: center;
`

const DiscordActionButton = styled(ActionButton)`
    width: 180px;
    background-color: #5865F2;
    font-size: 0.85rem;
    gap: 0.4rem;

    &:hover {
        background-color: #4e5ada;
        transition: 100ms linear;
    }
`

const DiscordButton: FC<React.ButtonHTMLAttributes<HTMLButtonElement> & { connected: boolean }> = ({ children, connected, ...args }) => {
    return (
        <DiscordActionButton {...args}>
            <img src={ DiscordLogo } style={{ height: "0.8rem", margin: "0" }} />
            {connected ? "Disconnect discord" : "Connect discord"}
            {children}
        </DiscordActionButton>
    )
}

const formatAttributes = (user: PasswordlessUser) => {
    const attr = [];
    if(user.verified)
        attr.push("Verified");
    if(user.beta)
        attr.push("Beta");
    if(user.admin)
        attr.push("Admin");
    return attr.length ? attr.join(", ") : "None";
}

const ProfileInfo = () => {
    const user = useStoreState(state => state.user.data!);
    const updateUserData = useStoreActions(state => state.user.updateUserData);

    const [ status,,, transform ] = useTransform((axios) => axios.post("/discord/unlink"));

    useEffect(() => {
        if(status !== "done") return;

        updateUserData({ discordId: undefined });
    }, [status]);

    return (
        <SectionContainer>
            <SectionHeader>
                <span>Info</span>
                {user.discordId ? 
                    <DiscordButton connected={!!user.discordId} onClick={transform} />
                :
                    <a href={process.env.DISCORD_LINK_URL} style={{ textDecoration: "none" }}>
                        <DiscordButton connected={!!user.discordId} />
                    </a>
                }
            </SectionHeader>
            <TableWrapper>
                <TableContainer>
                    <TableHeadingContainer>
                        <TableHeading basis={19}>ID</TableHeading>
                        <TableHeading basis={23}>USER</TableHeading>
                        <TableHeading basis={25}>DISCORD ID</TableHeading>
                        <TableHeading basis={13} $center>ATRIBUTTES</TableHeading>
                        <TableHeading basis={20} $center>CREATED</TableHeading>
                    </TableHeadingContainer>
                    <TableContentContainer>
                        <TableRowContainer>
                            <TableEntry basis={19}>{user.id}</TableEntry>
                            <TableEntry basis={23}>{user.username} (<span style={{ color: "#636363" }}>{user.email}</span>)</TableEntry>
                            <TableEntry basis={25}>{user.discordId ?? "Not set"}</TableEntry>
                            <TableEntry basis={13}><Centered>{formatAttributes(user)}</Centered></TableEntry>
                            <TableEntry basis={20}><Centered>{formatDateTime(new Date(user.createdAt))}</Centered></TableEntry>
                        </TableRowContainer>
                    </TableContentContainer>
                </TableContainer>
            </TableWrapper>
        </SectionContainer>
    )
}

export default ProfileInfo;