import { PlanDetails } from "./PaymentDetails";
import { ServiceType } from "./Services";

export const Plans: { [k in ServiceType]: PlanDetails[] } = {
    "mcpaste": [
        {
            available: true,
            name: "Free",
            price: 0,
            perks: [
                "limited pastes(240/day)",
            ]
        },
        {
            available: true,
            name: "Premium",
            price: 5,
            perks: [
                "unlimited pastes",
                "premium support",
                "customizable paste page",
                "customizable subdomain"
            ]
        },
        {
            available: false,
            name: "Enterprise",
            price: 15,
            perks: [
                "unlimited pastes",
                "priority premium support",
                "custom domain with SSL",
                "HTML customization"
            ]
        }
    ] 
};