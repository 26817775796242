import { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useStoreActions } from "../../../../hooks/state";
import { useTransform } from "../../../../hooks/useHttp";
import ActionButton from "../../../elements/ActionButton";
import { GamepanelPanel } from "./Panels";

const PanelContainerBox = styled.div`
    padding: 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
`;

const BoxButtons = styled.div`
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
`;

type Properties = {
    panel: GamepanelPanel;
    onDelete: () => void;
};

const PanelContainer: FC<Properties> = ({ panel: _panel, onDelete }) => {
    const [panel, setPanel] = useState<GamepanelPanel>(_panel);

    const activateModal = useStoreActions((state) => state.modals.activateModal);

    useEffect(() => {
        setPanel(_panel);
    }, [_panel]);

    const [deleteStatus, , , deleteTransform] = useTransform((axios) =>
        axios.delete(`/admin/game-panel/panels/${panel.id}`),
    );

    const [updateStatus, updateData, , updateTransform] = useTransform<
        {
            host: string;
            suspended: boolean;
        },
        {
            host: string;
            suspended: boolean;
        }
    >((axios, data) => axios.patch(`/admin/game-panel/panels/${panel.id}`, data));

    useEffect(() => {
        if (updateStatus !== "done" || !updateData) return;
        setPanel((panel) => ({
            ...panel,
            ...updateData,
            suspended_at: updateData.suspended ? Date.now() : null,
        }));
    }, [updateStatus]);

    useEffect(() => {
        if (deleteStatus !== "done" || !deleteStatus) return;
        onDelete();
    }, [deleteStatus, onDelete]);

    const doSuspend = useCallback((suspended: boolean) => {
        // currently only does suspended_at
        updateTransform({
            host: panel.host,
            suspended,
        });
    }, []);

    const handleDelete = () => {
        activateModal({
            type: "confirm",
            data: {
                title: `Delete panel at ${panel.host}?`,
                message: `Are you sure you want to delete panel at ${panel.host}?`,
                onConfirm: deleteTransform,
            },
        });
    };

    const handleSuspend = useCallback(
        (suspended: boolean) => {
            activateModal({
                type: "confirm",
                data: {
                    title: `Suspend panel at ${panel.host}?`,
                    message: `Are you sure you want to suspend panel at ${panel.host}?`,
                    confirmButton: "Suspend",
                    onConfirm: () => doSuspend(suspended),
                },
            });
        },
        [activateModal, doSuspend],
    );

    const isSuspended = useMemo(
        () => panel.suspended_at !== null && panel.suspended_at <= Date.now(),
        [panel],
    );

    return (
        <PanelContainerBox>
            <span>{panel.id}</span>
            <span style={{ fontSize: "1.2rem" }}>{panel.host}</span>
            <BoxButtons>
                <ActionButton
                    style={{ width: "100px" }}
                    onClick={() => handleSuspend(!isSuspended)}
                >
                    {!isSuspended ? "Suspend" : "Unsuspend"}
                </ActionButton>
                <ActionButton style={{ width: "100px" }} $red onClick={handleDelete}>
                    Delete
                </ActionButton>
            </BoxButtons>
        </PanelContainerBox>
    );
};

export default PanelContainer;
