import qs from "qs";
import { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useHistory, useParams } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useTransform } from "../../hooks/useHttp";
import { withCaptcha } from "../../hooks/withCaptcha";
import FullPageLoading from "../elements/FullPageLoading";

type RawParams = {
    userId: string,
    token: string
}

type Params = {
    userId: string,
    emailToken: string
}

type CaptchaParams = Params & { token: string }

const EmailConfirm = () => {
    const { setToken } = useAuth();
    const { userId, token: emailToken } = useParams<RawParams>();

    const [ status,, errors, transform ] = useTransform<unknown, CaptchaParams>((axios, params) => axios.post("/auth/update-email", params));

    const { executeRecaptcha } = useGoogleReCaptcha();

    const history = useHistory();

    useEffect(() => {
        if(!executeRecaptcha) return;
        executeRecaptcha()
            .then(token => transform({ userId, emailToken, token }));
    }, [executeRecaptcha]);

    useEffect(() => {
        setToken("");
        if(status === "errored")
            return history.push(`/?${qs.stringify({ errors })}`);

        if(status !== "done") return;
        history.push(`/?${qs.stringify({ messages: ["Confirmation successfull"] })}`);
    }, [status]);

    return (
        <FullPageLoading text="Confirming your email..." />
    );
};

export default withCaptcha(EmailConfirm);