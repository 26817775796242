import { useState } from "react";
import { useEffect } from "react";

import { useHttp } from "./useHttp";

export const useBraintreeToken = () => {
    const [ status, data ] = useHttp<{ token: string }>((axios) => axios.get("/payments/braintree/client-token"));

    const [ token, setToken ] = useState<string | null>(null);

    useEffect(() => {
        if(status !== "done" || !data) return;

        setToken(data.token);
    }, [status, data]);

    return token;
};