import { useStripe } from "@stripe/react-stripe-js";
import { SetupIntent, StripeCardNumberElement } from "@stripe/stripe-js";
import { FC, useState } from "react";
import styled from "styled-components";

import { doRequest } from "../../../../hooks/useHttp";
import { withNav } from "../../../../hooks/withNavbar";
import { withPaymentElements } from "../../../../hooks/withPaymentElements";
import LinkButton from "../../../elements/LinkButton";
import PaymentForm from "../../../elements/PaymentForm";

const ParentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
`;

type StatusMessage = {
    type: "error" | "success" | "info",
    message: string
}

const MessageContainer = styled.div<{ type: "error" | "success" | "info" }>`
    display: flex;
    flex-direction: column;
    color: ${p => p.type === "error" ? "#f04f4f" : p.type === "success" ? "#009b00" : "#4f4ff0"};
`;

type FinalizeError =
    | "paypal-error"
    | "invalid-method"
    | "method-already-exists"

const AddPaymentMethod: FC = () => {
    const stripe = useStripe();

    const [ processing, setProcessing ] = useState(false);

    const [ message, setMessage ] = useState<StatusMessage | null>(null);

    const handleStripeSubmit = (cardData: StripeCardNumberElement | { token: string }, completeFunc?: (s: "success" | "fail") => void) => {
        setProcessing(true);
        (async () => {
            if(!stripe) return;

            const [ data ] = await doRequest<{ secret: string }>((axios) => axios.get("/payments/stripe/setup"));
            const { secret } = data!;
    
            const { error, setupIntent } = await stripe.confirmCardSetup(secret, {
                payment_method: {
                    card: cardData
                }
            });
    
            if(error?.message) {
                return completeFunc ? completeFunc("fail") : setMessage({
                    type: "error",
                    message: error.message
                });
            }

            handleStripePaymentResponse(setupIntent!, completeFunc);
        })().finally(() => setProcessing(false));
    };

    const handleStripePaymentResponse = async (setupIntent: SetupIntent, completeFunc?: (s: "success" | "fail") => void) => {
        switch(setupIntent?.status) {
        case "succeeded":
            completeFunc && completeFunc("success");
            setMessage({
                type: "success",
                message: "Success! Your payment method has been saved."
            });
            break;
        case "processing":
            completeFunc && completeFunc("success");
            setMessage({
                type: "info",
                message: "Processing payment details. Expect them added in the next few minutes!"
            });
            break;
        default:
            completeFunc && completeFunc("fail");
            completeFunc ? completeFunc("fail") : setMessage({
                type: "error",
                message: "There have been some issues processing your payment method."
            });
            break;
        }
    };

    const submitPaypalNonce = async (nonce: string) => {
        const [ , errors ] = await doRequest((axios) => axios.post("/payments/braintree/vault-finalize", { nonce }));

        if(!errors.length)
            return setMessage({
                type: "success",
                message: "Success! Your payment method has been saved."
            });
        
        const errorType = errors[0] as FinalizeError;

        let message = "Something failed on our end. Please try again in a few minutes. Sorry :/";
        switch(errorType) {
        case "paypal-error":
            message = "There were some issues communicating with PayPal. Please try again in a few minutes.";
            break;
        case "method-already-exists":
            message = "This payment method is already linked with you account!";
            break;
        case "invalid-method":
            message = "We only accept Paypal accounts! If you wish to add a card, use the form below.";
            break;
        }

        setMessage({
            type: "error",
            message
        });
    };

    return (
        <ParentContainer>
            <Container>
                {message && 
                    <MessageContainer type={message.type}>
                        {message.message}
                    </MessageContainer>
                }
                <PaymentForm
                    buttonText="Add card"
                    paypalButtonLabel="paypal"
                    includeGooglePay={true}
                    disabled={!stripe || processing}
                    onStripeSubmit={handleStripeSubmit}
                    googlePayButtonLabel="plain"
                    googlePayTransactionInfo={{
                        label: "HM4 Card Save",
                        price: 0
                    }}
                    onGoogleSubmit={(data) => {
                        handleStripeSubmit({ token: JSON.parse(data.paymentMethodData.tokenizationData.token).id });
                    }}
                    paypal={{
                        createBillingAgreement: (actions) => {
                            return actions.braintree.createPayment({
                                flow: "vault",

                                billingAgreementDescription: "HM4 Card save",
                                currency: "USD"
                            });
                        },
                        onApprove: async (data, actions) => {
                            const tokenized = await actions.braintree.tokenizePayment(data);
                            submitPaypalNonce(tokenized.nonce);
                        }
                    }}
                />
                <LinkButton to="/account/billing" text="Go back" />
            </Container>
        </ParentContainer>
    );
};

export default withNav(withPaymentElements(AddPaymentMethod));