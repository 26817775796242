import qs from "qs";
import { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useHistory, useParams } from "react-router-dom";
import { Snowflake } from "snowflake";

import { useTransform } from "../../hooks/useHttp";
import { withCaptcha } from "../../hooks/withCaptcha";
import FullPageLoading from "../elements/FullPageLoading";

type RawParams = {
    userId: Snowflake,
    token: string
}

type Params = {
    userId: Snowflake,
    verificationToken: string
}

type CaptchaParams = Params & { token: string }

const Verification = () => {
    const { token: verificationToken, userId } = useParams<RawParams>();

    const [ status,, errors, transform ] = useTransform<unknown, CaptchaParams>((axios, params) => axios.post("/auth/verify", params));

    const { executeRecaptcha } = useGoogleReCaptcha();

    const history = useHistory();

    useEffect(() => {
        if(!executeRecaptcha) return;
        executeRecaptcha()
            .then(token => transform({ userId, verificationToken, token }));
    }, [executeRecaptcha]);

    useEffect(() => {
        if(status === "errored")
            return history.push(`/?${qs.stringify({ errors })}`);

        if(status !== "done") return;
        history.push(`/?${qs.stringify({ messages: ["Verification successfull"] })}`);
    }, [status]);

    return (
        <FullPageLoading text="Verifying your account..." />
    );
};

export default withCaptcha(Verification);