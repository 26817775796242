import { FC } from "react";

import PaymentElements from "../components/elements/PaymentElements";

export const withPaymentElements = (Component: FC) => {
    return () => (
        <PaymentElements>
            <Component />
        </PaymentElements>
    );
};