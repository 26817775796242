import styled from "styled-components";

const CopyrightContainer = styled.div`
    color: rgba(0, 0, 0, 0.6);
    cursor: default;
`;

const Copyright = () => {
    return (
        <CopyrightContainer>
            © HM4 2021 - { new Date().getFullYear() }
        </CopyrightContainer>
    );
};

export default Copyright;