import { useFormik } from "formik";
import { User } from "models";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useStoreActions, useStoreState } from "../../../hooks/state";
import { useAuth } from "../../../hooks/useAuth";
import { useTransform } from "../../../hooks/useHttp";
import InputLabel from "../../auth/elements/AuthInputLabel";
import Button from "../../elements/Button";
import SectionContainer from "../../elements/SectionContainer";
import SectionHeader from "../../elements/SectionHeader";
import TextInput from "../../elements/TextInput";

const EditContiner = styled.div`
    display: flex;
    gap: 2rem;
    align-items: stretch;
    justify-content: space-evenly;
    padding: 1rem;
    /* flex-wrap: wrap; */

    @media (max-width: 1300px) {
        flex-direction: column;
        align-items: stretch;
    }
`

const Divider = styled.div`
    width: 1px;
    background-color: #c0c0c0;

    @media (max-width: 1300px) {
        display: none;
    }
`

const EditSectionContatiner = styled.form`
    display: fleX;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    width: auto;
    flex-grow: 1;

    @media (max-width: 1300px) {
        flex-direction: column;
        align-items: stretch;
    }
`

const InputContainer = styled.div`
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`

const UpdateButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

type BasicUserInfo = Pick<User, "username">

const ProfileEdit = () => {
    const { setToken } = useAuth();

    const { username, email } = useStoreState(state => state.user.data!);
    
    const updateUserData = useStoreActions(state => state.user.updateUserData);

    const [ dataStatus,, dataErrors, dataTransform ] = useTransform<never, BasicUserInfo>((axios, data) => axios.post("/user/update", data));
    const [ emailStatus,, emailErrors, emailTransform ] = useTransform<never, { email: string }>((axios, data) => axios.post("/user/update-email", data));
    const [ passwordStatus, passwordData, passwordErrors, passwordTransform ] = useTransform<{ newToken: string }, { oldPassword: string, newPassword: string }>((axios, data) => axios.post("/user/update-password", data));
    
    const errors = useMemo(() => [ ...dataErrors, ...emailErrors, ...passwordErrors ], [dataErrors, emailErrors, passwordErrors]);

    const [ emailSent, setEmailSent ] = useState(false);

    const dataFormik = useFormik<BasicUserInfo>({
        initialValues: {
            username
        },
        onSubmit: dataTransform
    });

    const emailFormik = useFormik<{ email: string }>({
        initialValues: {
            email
        },
        onSubmit: emailTransform
    });

    const passwordFormik = useFormik<{ oldPassword: string, newPassword: string }>({
        initialValues: {
            oldPassword: "",
            newPassword: ""
        },
        onSubmit: passwordTransform
    });

    useEffect(() => {
        if(dataStatus !== "done") return;

        updateUserData(dataFormik.values);
    }, [dataStatus]);

    useEffect(() => {
        if(emailStatus !== "done") return;

        setEmailSent(true);
    }, [emailStatus]);

    useEffect(() => {
        if(passwordStatus !== "done" || !passwordData) return;

        setToken(passwordData.newToken);
    }, [passwordStatus]);

    return (
        <SectionContainer>
            <SectionHeader>
                <span>Edit</span>
                {emailSent ? 
                    <span style={{ color: "#5CBE77" }}>A verification email has been sent to { emailFormik.values.email }</span>
                :
                    !!errors.length &&
                        <span style={{ color: "red" }}>{errors[0]}</span>
                }
                <span></span>
            </SectionHeader>
            <EditContiner>
                <EditSectionContatiner onSubmit={dataFormik.handleSubmit}>
                    <InputContainer>
                        <div>
                            <InputLabel text="Username" />
                            <TextInput type="text" name="username" formik={dataFormik} />
                        </div>
                    </InputContainer>
                    <UpdateButtonContainer><Button disabled={dataStatus === "loading"}>Update</Button></UpdateButtonContainer>
                </EditSectionContatiner>
                <Divider />
                <EditSectionContatiner onSubmit={emailFormik.handleSubmit}>
                    <InputContainer>
                        <div>
                            <InputLabel text="Email" />
                            <TextInput type="text" name="email" formik={emailFormik} disabled={emailSent} />
                        </div>
                    </InputContainer>
                    <UpdateButtonContainer><Button disabled={emailStatus === "loading" || emailSent}>Update</Button></UpdateButtonContainer>
                </EditSectionContatiner>
                <Divider />
                <EditSectionContatiner onSubmit={passwordFormik.handleSubmit}>
                    <InputContainer>
                        <div>
                            <InputLabel text="Old password" />
                            <TextInput type="password" name="oldPassword" placeholder="******" formik={passwordFormik} />
                        </div>
                        <div>
                            <InputLabel text="New password" />
                            <TextInput type="password" name="newPassword" placeholder="******" formik={passwordFormik} />
                        </div>
                    </InputContainer>
                    <UpdateButtonContainer><Button disabled={passwordStatus === "loading"}>Update</Button></UpdateButtonContainer>
                </EditSectionContatiner>
            </EditContiner>
        </SectionContainer>
    )
}

export default ProfileEdit;