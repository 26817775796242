import ansiStyles from "ansi-styles";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { useHttp } from "../../../hooks/useHttp";
import { useSocket } from "../../../hooks/useSocket";
import { withNav } from "../../../hooks/withNavbar";
import { formatDateTime } from "../../../lib/date";
import Button from "../../elements/Button";
import CenteredLoadingSpinner from "../../elements/CenteredLoadingSpinner";
import XTerminal from "../../elements/XTerminal";

type CronInfoRaw = {
    lastRun: string
}

type CronInfo = {
    lastRun: Date
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
`

const ButtonContainer = styled.div`
    display: flex;
    gap: 2rem;
    width: 400px;
`

const LogContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
`

const formatSocketMessage = (msg: string) => 
    ansiStyles.yellow.open + "[Socket] " + ansiStyles.reset.open + msg + ansiStyles.reset.open;

const Cron = () => {
    const [ status, cronInfoRaw ] = useHttp<CronInfoRaw>(axios => axios.get("/admin/system/cron"));
    const [ lastLogStatus, lastLogData ] = useHttp<{ content: string }>(axios => axios.get("/admin/system/latest-cron-log"));

    const [ cronInfo, setCronInfo ] = useState<CronInfo>();
    const [ lastLog, setLastLog ] = useState("");

    const [ clearState, setClearState ] = useState(false);
    const writeRef = useRef<(msg: string) => void>(console.log);

    const socket = useSocket("/socket/admin");

    useEffect(() => {
        if(status !== "done" || !cronInfoRaw) return;
        setCronInfo({ ...cronInfoRaw, lastRun: new Date(cronInfoRaw.lastRun) });
    }, [status, cronInfoRaw]);

    useEffect(() => {
        if(lastLogStatus !== "done" || !lastLogData) return;
        setLastLog(lastLogData.content);
    }, [lastLogStatus, lastLogData]);

    useEffect(() => {
        if(!socket) return;

        socket.on("connect", () => 
            writeRef.current(formatSocketMessage(ansiStyles.greenBright.open + "Connection to server established!" + ansiStyles.greenBright.close))
        );

        socket.on("disconnect", () =>
            writeRef.current(formatSocketMessage(ansiStyles.red.open + "Connection to server dropped! Will attempt to reconnect." + ansiStyles.red.close))
        );

        socket.on("cronLiveLog", msg => writeRef.current(msg));
        socket.on("cronEnd", () => setCronInfo({ lastRun: new Date() }));
    }, [socket]);

    const runNow = () => {
        if(!socket) return;
        socket.emit("startCron");
    };

    if(!cronInfo || !socket)
        return <CenteredLoadingSpinner />;

    return (
        <Container>
            <span style={{ fontSize: "1.3rem" }}>Last run&#58; {formatDateTime(cronInfo.lastRun)}</span>
            <ButtonContainer>
                <Button onClick={runNow}>Run now</Button>
                <Button onClick={() => setClearState(it => !it)}>Clear</Button>
            </ButtonContainer>
            <LogContainer>
                <XTerminal title="Live log" writeRef={writeRef} clearState={clearState} />
                <XTerminal title="Previous log" staticContent={lastLog} />
            </LogContainer>
        </Container>
    );
};

export default withNav(Cron);
