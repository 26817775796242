import "./xterm.css";

import { FC, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { ITerminalOptions, ITheme, Terminal } from "xterm";

type Props = {
    title: string,
    clearState?: boolean,
    writeRef?: { current: (msg: string) => void },
    staticContent?: string
}

const terminalTheme: ITheme = {
    background: "#131a20",
    cursor: "transparent",
    black: "#131a20",
    red: "#E54B4B",
    green: "#9ECE58",
    yellow: "#FAED70",
    blue: "#396FE2",
    magenta: "#BB80B3",
    cyan: "#2DDAFD",
    white: "#d0d0d0",
    brightBlack: "rgba(255, 255, 255, 0.2)",
    brightRed: "#FF5370",
    brightGreen: "#C3E88D",
    brightYellow: "#FFCB6B",
    brightBlue: "#82AAFF",
    brightMagenta: "#C792EA",
    brightCyan: "#89DDFF",
    brightWhite: "#ffffff",
    selection: "#FAF089"
};

const terminalProps: ITerminalOptions = {
    disableStdin: true,
    cursorStyle: "underline",
    fontSize: 14,
    fontFamily: "Consolas, monospace",
    rows: 30,
    theme: terminalTheme
};

const TerminalContainer = styled.div`
    background-color: #131a20;
    padding: 0.6rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        color: white;
    }

    #terminal {
        padding-right: 0.6rem;
    }
`;

const XTerminal: FC<Props> = ({ title, writeRef, staticContent, clearState }) => {
    const targetRef = useRef<HTMLDivElement>(null);
    const xterm = useMemo(() => new Terminal(terminalProps), []);

    useEffect(() => {
        if(!writeRef) return;
        writeRef.current = (msg) => xterm.writeln(msg.replace(/(?:\r\n|\r|\n)$/im, "") + "\u001b[0m");
    }, []);

    useEffect(() => {
        if(!staticContent) return;
        xterm.clear();
        staticContent.split("\n").forEach(it => {
            xterm.writeln(it + "\u001b[0m");
        });
    }, [staticContent]);

    useEffect(() => {
        xterm.clear();
    }, [clearState]);

    useEffect(() => {
        if(!targetRef.current || xterm.element) return;
        xterm.open(targetRef.current);
    }, [xterm]);

    return (
        <TerminalContainer>
            <span>{ title }</span>
            <div id="terminal" ref={targetRef}></div>
        </TerminalContainer>
    );
};

export default XTerminal;