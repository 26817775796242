import { BraintreePayPalCheckoutCreatePaymentOptions } from "@paypal/react-paypal-js/dist/types/types/braintree/paypalCheckout";
import { useStripe } from "@stripe/react-stripe-js";
import { PaymentIntent, StripeCardNumberElement } from "@stripe/stripe-js";
import { useFormik } from "formik";
import { PaymentMethod } from "models";
import { PlanDetails } from "models";
import { Plans } from "models";
import { Services } from "models";
import { hashMethod, ServiceValidators } from "payments";
import qs from "qs";
import { FC, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useStoreState } from "../../../hooks/state";
import { doRequest, useHttp } from "../../../hooks/useHttp";
import { useQuery } from "../../../hooks/useQuery";
import { withNav } from "../../../hooks/withNavbar";
import { withPaymentElements } from "../../../hooks/withPaymentElements";
import { PaymentData } from "../../../lib/paymentDataParser";
import { capitalize } from "../../../lib/text";
import FormWrapper from "../../auth/elements/AuthFormWrapper";
import LoadingSpinner from "../../elements/LoadingSpinner";
import PaymentForm, { PaymentButton } from "../../elements/PaymentForm";
import SelectDropdown from "../../elements/SelectDropdown";
import { CardDigitQuartet, getPaymentMethodIconElement } from "../profile/billing/PaymentMethodContainer";

type PaymentPlanInfo = {
    data: PaymentData,
    detials: PlanDetails
}

type CreateOrderOptions = BraintreePayPalCheckoutCreatePaymentOptions & {
    requestBillingAgreement?: boolean,
    billingAgreementDetails?: {
        description: string
    }
}

const ParentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 2rem;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 2rem;
    
    @media (max-width: 1120px) {
        flex-direction: column;
    }
`;

const InfoContainer = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;

    span {
        word-break: break-all;
    }
`;

const Title = styled.div`
    font-size: 1.4rem;
`;

const Divider = styled.div`
    height: auto;
    width: 1px;
    background-color: #040404;

    @media (max-width: 1120px) {
        display: none;
    }
`;

const SideContianer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 400px;
    padding: 1rem;
`;

const LinkWrapper = styled.div`
    cursor: pointer;
    color: #408CFF;
    text-decoration: underline;
`;

const MessageContainer = styled.div<{ type: "error" | "success" | "info" }>`
    display: flex;
    flex-direction: column;
    color: ${p => p.type === "error" ? "#f04f4f" : p.type === "success" ? "#009b00" : "#4f4ff0"};
`;

const MethodFlexRow = styled.div`
    display: flex;
    align-items: center;
    gap: 0.6rem;
    padding: 0.8rem;
    padding-right: 0.8rem;
    background-color: #eeeeee;
    height: 50px;

    span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`

export const MethodIconContainer = styled.div`
    display: flex;
    width: 40px;
    min-width: 40px;
    justify-content: center;
    align-items: center;
`

type StatusMessage = {
    type: "error" | "success" | "info",
    message: string
}

type PaymentError =
    | "invalid-service"
    | "charge-failed"
    | "unreachable-segment"
    | "owned-service";

type SaveError =
    | "paypal-error"
    | "invalid-method"
    | "invaid-service-plan"
    | "owned-service"

const PaymentProcess: FC<PaymentPlanInfo> = ({ data, detials: details }) => {
    const userId = useStoreState(state => state.user.data!.id);

    const [ status, methods ] = useHttp<PaymentMethod[]>(axios => axios.get("/payments/payment-methods"), { cache: false });
    const { service, plan, metadata } = data;

    const stripe = useStripe();

    const [ showCurrent, setShowCurrent ] = useState<boolean>();
    const [ message, setMessage ] = useState<StatusMessage | null>(null);
    const [ processing, setProcessing ] = useState(false);

    const handleStripeSubmit = (cardData: StripeCardNumberElement | { token: string }, completeFunc?: (s: "success" | "fail") => void) => {
        setProcessing(true);
        (async () => {
            if(!stripe) return;

            const [ data, errors ] = await doRequest<{ secret: string }>(axios => axios.get(`/payments/stripe/payment?${qs.stringify({ service, plan, userId, metadata })}`));
            if(errors.length > 0)
                return handlePaymentError(errors[0] as PaymentError);
            const { secret } = data!;

            const { error, paymentIntent } = await stripe.confirmCardPayment(secret, {
                payment_method: {
                    card: cardData
                }
            });

            if(error?.message) {
                return completeFunc ? completeFunc("fail") : setMessage({
                    type: "error",
                    message: error.message
                });
            }

            handleStripePaymentResponse(paymentIntent!, completeFunc);
        })().finally(() => setProcessing(false));
    };

    const handleStripePaymentResponse = async (paymentIntent: PaymentIntent, completeFunc?: (s: "success" | "fail") => void) => {
        switch(paymentIntent?.status) {
        case "succeeded":
            completeFunc && completeFunc("success");
            setMessage({
                type: "success",
                message: "Success! Your purchase is complete!"
            });
            break;
        case "processing":
            completeFunc && completeFunc("success");
            setMessage({
                type: "info",
                message: "Processing payment details. Expect your service in the next few minutes!"
            });
            break;
        default:
            completeFunc && completeFunc("fail");
            completeFunc ? completeFunc("fail") : setMessage({
                type: "error",
                message: "There have been some issues processing your payment method."
            });
            break;
        }
    };

    const submitPaypalnonce = async (nonce: string) => {
        const [ , errors ] = await doRequest((axios) => axios.post("/payments/braintree/payment-finalize", { nonce, service, plan, metadata }));

        if(!errors.length)
            return setMessage({
                type: "success",
                message: "Success! Your purchase is complete!"
            });
        
        const errorType = errors[0] as SaveError;

        let message = "Something failed on our end. Please try again in a few minutes. Sorry :/";
        switch(errorType) {
        case "paypal-error":
            message = "There were some issues communicating with PayPal. Please try again in a few minutes.";
            break;
        case "invalid-method":
            message = "We only accept Paypal accounts! If you wish to add a card, use the form below.";
            break;
        case "owned-service":
            message = "You already own this plan!";
        }

        setMessage({
            type: "error",
            message
        });
    };

    const completeSavedPayment = async ({ method: hash }: { method: string }) => {
        const method = getMethodByHash(hash)  ;
        if(!method) return;
        
        const [ , errors ] = await doRequest(axios => axios.post("/payments/process", {
            method: method.type === "card" ?
                { fingerprint: method.fingerprint, source: method.source } :
                { email: method.email },
            payment: {
                data,
                details
            }
        }
        ));

        if(!errors.length) {
            return setMessage({
                type: "success",
                message: "Success! Your purchase is complete!"
            });
        }

        const error = errors[0] as PaymentError;
        handlePaymentError(error);
    };

    const handlePaymentError = (error: PaymentError) => {
        switch(error) {
        case "invalid-service":
            setMessage({
                type: "error",
                message: "Invalid service"
            });
            break;
        case "charge-failed":
            setMessage({
                type: "error",
                message: "Charge of payment method failed"
            });
            break;
        case "owned-service":
            setMessage({
                type: "error",
                message: "You already own this plan!"
            });
            break;
        case "unreachable-segment":
            setMessage({
                type: "error",
                message: "Uhh, this should not have happened, the fuck?!?!"
            });
            break;
        }
    };

    const getMethodByHash = (hash: string) => methods?.find(it => hashMethod(it) === hash);

    const formik = useFormik<{ method: string }>({
        initialValues: {
            method: ""
        },
        onSubmit: (values) => {
            setProcessing(true);
            completeSavedPayment(values).finally(() => setProcessing(false));
        }
    });

    useEffect(() => {
        if(status !== "done" || !methods) return;

        setShowCurrent(methods.length > 0);
        if(methods.length > 0)
            formik.setValues({ method: hashMethod(methods.find(it => it.primary)!) });
    }, [status, methods]);

    return (
        <ParentContainer>
            {message && 
                <MessageContainer type={message.type}>
                    {message.message}
                </MessageContainer>
            }
            <Container>
                {status !== "done" || !methods || showCurrent === undefined ? <LoadingSpinner /> :
                <>
                    <InfoContainer>
                        <Title>{ capitalize(service) }</Title>
                        <span>{ plan }</span>
                        {Object.entries(metadata).map(([k, v]) => <span key={k}><b>{ capitalize(k) }<br /></b>{ v }</span>)}
                    </InfoContainer>
                    <Divider />
                    {showCurrent ?
                        <SideContianer>
                            <FormWrapper onSubmit={formik.handleSubmit}>
                                <span>Choose a pre-saved method</span>
                                <SelectDropdown
                                    values={methods}
                                    defaultValue={formik.values.method}
                                    defaultSelector={(it) => hashMethod(it)}
                                    onChange={(it) => formik.setValues({ method: hashMethod(it) })}
                                    each={(it) => 
                                        <MethodFlexRow>
                                            <MethodIconContainer>{getPaymentMethodIconElement(it)}</MethodIconContainer>
                                            {it.type === "card" && <CardDigitQuartet />}
                                            <span>{it.type === "card" ? it.last4 : it.email}</span>
                                        </MethodFlexRow>
                                    }
                                />
                                <PaymentButton style={{ width: "100%" }} disabled={!stripe || processing}>Pay now</PaymentButton>
                            </FormWrapper>
                            <LinkWrapper onClick={() => setShowCurrent(false)}>or use a new method</LinkWrapper>
                        </SideContianer>
                        :
                        <SideContianer>
                            <PaymentForm
                                buttonText={`Pay $${details.price.toFixed(2)}`}
                                paypalButtonLabel="pay"
                                includeGooglePay={true}
                                disabled={!stripe || processing}
                                onStripeSubmit={handleStripeSubmit}
                                googlePayButtonLabel="buy"
                                googlePayTransactionInfo={{
                                    label: `${capitalize(service)} ${capitalize(plan)} Plan`,
                                    price: details.price
                                }}
                                onGoogleSubmit={(data) => handleStripeSubmit({ token: JSON.parse(data.paymentMethodData.tokenizationData.token).id })}
                                paypal={{
                                    createOrder: (actions) => {
                                        return actions.braintree.createPayment({
                                            flow: "checkout",
                                            amount: details.price,
                                            currency: "USD",
                                            requestBillingAgreement: true,
                                            billingAgreementDetails: {
                                                description: `HM4 | ${capitalize(service)} ${capitalize(plan)} Plan`
                                            },

                                            intent: "capture"
                                        } as CreateOrderOptions);
                                    },
                                    onApprove: async (data, actions) => {
                                        const tokenized = await actions.braintree.tokenizePayment(data);
                                        submitPaypalnonce(tokenized.nonce);
                                    }
                                }}
                            />
                            {methods.length > 0 && 
                                <LinkWrapper onClick={() => setShowCurrent(true)}>or use a saved method</LinkWrapper>}
                        </SideContianer>
                    }
                </>
                }
            </Container>
        </ParentContainer>
    );
};

// performs all validations checks needed for component, also initializes stripe and paypal
export default withNav(() => {
    const history = useHistory();
    const data = useQuery() as PaymentData;

    const [ planDetails, setPlanDetails ] = useState<PlanDetails | null>(null);

    const [ passed, setPassed ] = useState(false);
    
    // handle missing data
    useEffect(() => {
        // handle errors maybe
        if(!data.service || !Services.includes(data.service) || !data.plan || !data.metadata || !ServiceValidators[data.service](data.metadata))
            return history.push("/");
        
        const details = Plans[data.service].find(it => it.name.toLowerCase() === data.plan.toLowerCase());
        if(!details)
            return history.push("/");

        setPlanDetails(details);
        setPassed(true);
    }, []);

    if(!passed) return <div></div>;

    return withPaymentElements(() => <PaymentProcess data={data} detials={planDetails!} />)();
});