import { FC, useEffect,useRef,useState } from "react";
import styled from "styled-components";

import Fade from "./Fade";

type Props = {
    active: boolean,
    title: string,
    onExit?: () => void;
}

const Wrapper = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    gap: 1rem;
    color: rgb(10, 10, 10);
    z-index: 420;

    @media (max-height: 800px) {
        justify-content: center;
    }
`;

const ModalContainer = styled.div`
    width: fit-content;
    background-color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    max-width: 100vw;
    width: 500px;

    @media (min-height: 800px) {
        margin-top: 10rem;
    }
`;

const ModalTitle = styled.div`
    width: fit-content;
    font-size: 1.8rem;
`;

const Modal: FC<Props> = ({ active, title, children, onExit = () => {} }) => {
    const ref = useRef<HTMLDivElement>(null);
    
    const [ render, setRender ] = useState(active);
    
    // because of position relative somewhere in the node tree, this modal will position weirdly unless we don't put in in root
    useEffect(() => {
        const bind = document.querySelector("#modal-container");
        if(ref.current && bind)
            bind.appendChild(ref.current);
    }, []);

    useEffect(() => {
        setRender(active);
    }, [active]);

    useEffect(() => {
        document.documentElement.style["overflow"] = render ? "hidden" : "";
    }, [render]);

    return (
        <div ref={ref} style={{ position: "fixed", top: 0, left: 0, zIndex: 1e4 }}>
            <Fade in={render} timeout={100} unmountOnExit onExited={onExit} parentStyles={{
                position: "fixed",
                top: 0,
                left: 0,
            }}>
                <Wrapper onClick={(e) => {
                    if(e.target === e.currentTarget)
                        setRender(false);
                }}>
                    <ModalContainer>
                        <ModalTitle>{ title }</ModalTitle>
                        {children}
                    </ModalContainer>
                </Wrapper>
            </Fade>
        </div>
    );
};

export default Modal;