import styled from "styled-components";

const TableHeading = styled.div<{ $center?: boolean, basis?: number }>`
    font-size: 1.2rem;
    text-transform: uppercase;
    width: ${p => p.basis ?? 20}%;
    font-size: 1.2rem;
    font-weight: 300;
    ${p => p.$center ? "text-align: center;" : ""}
`;

export default TableHeading;