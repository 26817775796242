import styled from "styled-components";

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 1rem;
    min-width: 950px;
`;

export default TableContainer;