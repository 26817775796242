import { ServiceType } from "models";
import styled from "styled-components";
import { useHttp } from "../../../../hooks/useHttp";
import { capitalize } from "../../../../lib/text";
import CenteredLoadingSpinner from "../../../elements/CenteredLoadingSpinner";
import SectionContainer from "../../../elements/SectionContainer";
import SectionHeader from "../../../elements/SectionHeader";

const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
    padding: 0.8rem;
    overflow-x: auto;
    overflow-y: hidden;
`

const ActiveServiceContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    padding: 0.4rem;
    border-bottom: 1px solid #c3c3c3;
    gap: 0.4rem;
`;

const MetaList = styled.ul`
    margin-left: 1rem;
    
    li {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`

export type ActiveService = {
    service: ServiceType,
    plan: string,
    amount: number,
    metadata: { [k: string]: string }
};

const ActiveServices = () => {
    const [ status, active ] = useHttp<ActiveService[]>(axios => axios.get("/payments/active"));

    return (
        <SectionContainer>
            <SectionHeader>
                <span>Active Services</span>
                {(status === "done" && active) &&
                    <span><b style={{ margin: "0" }}>Estimated monthly cost&#58; </b>&#36;{active.reduce((acc, curr) => acc + curr.amount, 0)}</span>
                }
            </SectionHeader>
            {(status === "loading" || !active) ? <CenteredLoadingSpinner /> :
            <RowContainer>
                {active.map(service => 
                    <ActiveServiceContainer>
                        <span>{capitalize(service.service)} - {capitalize(service.plan)}</span>
                        <MetaList>
                            {Object.entries(service.metadata).map(([k, v]) => <li><b>{capitalize(k)}&#58; </b>{v}</li>)}
                        </MetaList>
                    </ActiveServiceContainer>
                )}
            </RowContainer>
            }
        </SectionContainer>
    )
}

export default ActiveServices;