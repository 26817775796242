import axios, { AxiosInstance, AxiosResponse } from "axios";
import nProgress from "nprogress";
import { useCallback, useEffect, useState } from "react";

import { store } from "../state";

// I thought this was gonna be easy to do
// should've just used react-query, but oh well

type GenericQueryResponse = {
    status: number;
    data: Record<string, unknown>;
    errors: string[];
};

type GenericQueryStatus = "prepared" | "loading" | "done" | "errored";

const instance = axios.create({
    baseURL: process.env.BASE_URL,
    timeout: 20000,
    validateStatus: () => true,
});

const requestCache: Map<string, [GenericQueryStatus, unknown, string[]]> = new Map();

instance.interceptors.request.use((config) => {
    const token = store.getState().auth.token;

    if (token && config.headers) config.headers["Authorization"] = `Bearer ${token}`;

    return config;
});

export type GenericQueryOptions<T> = {
    mod: (data: Record<string, unknown>) => T;
    autoProccessErrors: boolean;
    doRequest: boolean;
};

const defaultOpts = <T>(): GenericQueryOptions<T> => ({
    mod: (data) => data as T,
    autoProccessErrors: true,
    doRequest: true,
});

const makeRequest = <T = unknown, D = never>(
    handler: (axios: AxiosInstance, requestData: D) => Promise<AxiosResponse>,
    requestData: D,
    options: Partial<GenericQueryOptions<T>> = {},
): Promise<[T | undefined, string[]]> => {
    const opts = Object.assign<GenericQueryOptions<T>, Partial<GenericQueryOptions<T>>>(
        defaultOpts<T>(),
        options,
    );

    if (!opts.doRequest) return Promise.resolve([undefined, []]);

    nProgress.start();
    return handler(instance, requestData)
        .then((resp) => {
            const data: GenericQueryResponse = resp.data;
            const status = resp.status;
            // if we're unauthorized, redirect back to login
            // if we get something not found, there's something really wrong, so just go back to login
            if (opts.autoProccessErrors && [401, 403, 422].includes(status)) {
                store.getActions().auth.setToken("");
                nProgress.done();
                return [undefined, []];
            }

            nProgress.done();
            const didError = status < 200 || status >= 300;
            return didError ? [undefined, data.errors] : [opts.mod(data.data), []];
        })
        .catch((err) => {
            nProgress.done();
            return [undefined, [err]];
        }) as Promise<[T | undefined, string[]]>;
};

export const doRequest = <T = unknown>(
    handler: (axios: AxiosInstance) => Promise<AxiosResponse>,
    opts: Partial<GenericQueryOptions<T>> = defaultOpts<T>(),
): Promise<[T | undefined, string[]]> => {
    return makeRequest(handler, {}, opts);
};

export const useHttp = <T = unknown>(
    handler: (axios: AxiosInstance) => Promise<AxiosResponse>,
    opts: Partial<GenericQueryOptions<T> & { cache: boolean }> = {
        ...defaultOpts<T>(),
        cache: true,
    },
): [GenericQueryStatus, T | undefined, string[], () => void] => {
    const [status, setStatus] = useState<GenericQueryStatus>("prepared");
    const [data, setData] = useState<T>();
    const [errors, setErrors] = useState<string[]>([]);

    opts = { cache: true, ...opts };

    useEffect(() => {
        const cached = requestCache.get(handler.toString());
        if (opts.cache && cached) {
            const [s, d, e] = cached;
            setStatus(s);
            setData(d as T);
            setErrors(e);
        } else setStatus("loading");
        makeRequest(handler, {}, opts).then(([data, errors]) => {
            setData(data);
            setErrors(!data ? errors : []);
            setStatus(!data ? "errored" : "done");
        });
        // eslint-disable-next-line
    }, []);

    const refetch = useCallback(() => {
        makeRequest(handler, {}, opts).then(([data, errors]) => {
            setData(data);
            setErrors(!data ? errors : []);
            setStatus(!data ? "errored" : "done");
        });
    }, [setData, setErrors, setStatus]);

    useEffect(() => {
        requestCache.set(handler.toString(), [status, data, errors]);
    }, [status, data, errors]);

    return [status, data, errors, refetch];
};

export const useTransform = <T = unknown, D = never>(
    handler: (axios: AxiosInstance, requestData: D) => Promise<AxiosResponse>,
    opts: Partial<GenericQueryOptions<T>> = defaultOpts<T>(),
): [GenericQueryStatus, T | undefined, string[], (requestData: D) => Promise<void>] => {
    const [status, setStatus] = useState<GenericQueryStatus>("prepared");
    const [data, setData] = useState<T>();
    const [errors, setErrors] = useState<string[]>([]);

    const transform = async (requestData: D) => {
        setData(undefined);
        setErrors([]);
        setStatus("loading");
        await makeRequest(handler, requestData, opts).then(([data, errors]) => {
            setData(data);
            setErrors(!data ? errors : []);
            setStatus(!data ? "errored" : "done");
        });
    };

    return [status, data, errors, transform];
};

export const clearRequestCache = () => requestCache.clear();
