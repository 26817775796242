import { PaymentMethod, Transaction } from "models";
import { hashMethod } from "payments";
import { useHttp } from "../../../../hooks/useHttp";
import CenteredLoadingSpinner from "../../../elements/CenteredLoadingSpinner";
import SectionContainer from "../../../elements/SectionContainer";
import SectionHeader from "../../../elements/SectionHeader";
import TableContainer from "../../../elements/TableContainer";
import TableContentContainer from "../../../elements/TableContentContainer";
import TableHeading from "../../../elements/TableHeading";
import TableHeadingContainer from "../../../elements/TableHeadingContainer";
import TableWrapper from "../../../elements/TableWrapper";
import TransactionRow from "./TransactionRow";

const TransactionTable = () => {
    const [ status, transactions ] = useHttp<Transaction[]>((axios) => axios.get("/payments/transactions"));

    const [ ,methods ] = useHttp<PaymentMethod[]>(axios => axios.get("/payments/payment-methods"));

    return (
        <SectionContainer>
            <SectionHeader><span>Transactions</span></SectionHeader>
            <TableWrapper>
                <TableContainer>
                    <TableHeadingContainer>
                        <TableHeading basis={50}>PAYMENT METHOD</TableHeading>
                        {/* These margins are a weird hack to fix position on table headers */}
                        <TableHeading basis={10} style={{ marginLeft: "-20px" }}>SERVICE</TableHeading>
                        <TableHeading basis={10}>PLAN</TableHeading>
                        <TableHeading basis={10} style={{ paddingLeft: "20px" }} $center>AMOUNT</TableHeading>
                        <TableHeading $center>DATE</TableHeading>
                    </TableHeadingContainer>
                    {status === "loading" && <CenteredLoadingSpinner />}
                    <TableContentContainer>
                        {transactions?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                            .map(it => <TransactionRow key={it.id} transaction={it} methodUsed={methods?.find(m => hashMethod(m) === it.payment_method) ?? it.payment_method} />)
                        }
                    </TableContentContainer>
                </TableContainer>
            </TableWrapper>
        </SectionContainer>
    )
}

export default TransactionTable;