import styled from "styled-components";

type Props = {
    width?: string,
    height?: string
}

const Card = styled.div<Props>`
    position: relative;
    background-color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 100vw;
    width: ${p => p.width ?? "420px"};
    align-items: center;
    ${p => p.height ? `height: ${p.height}` : ""};
`;

export default Card;