import { FormikHelpers, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import { useTransform } from "../../hooks/useHttp";
import { withCaptcha } from "../../hooks/withCaptcha";
import Button from "../elements/Button";
import Card from "../elements/Card";
import CardDiv from "../elements/CardDiv";
import CardText from "../elements/CardText";
import CardTitle from "../elements/CardTitle";
import Wrapper from "../elements/FullPageWrapper";
import LinkButton from "../elements/LinkButton";
import TextInput from "../elements/TextInput";
import FormWrapper from "./elements/AuthFormWrapper";
import InputLabel from "./elements/AuthInputLabel";
import PasswordStrenghtIndicator, { PasswordStrenght } from "./elements/PasswordStrenghtIndicator";

type RawParams = {
    token: string,
    userId: string
}

type Fields = {
    newPassword: string
}

type RequestFields = Fields & {
    passwordToken: string,
    userId: string,
    token: string
}

const LinkWrapper = styled(Link)`
    color: #408CFF;
    text-decoration: underline;
`;

const ResetSchema = yup.object().shape({
    password: yup.string().required("Password is a required field!"),
    confirmPassword: yup.string().required("Password needs confirmation!").test(
        "confirm-password",
        "Passwords do not match",
        (value, context: { parent: { password: string } }) => context.parent.password === value
    )
});

const PasswordReset = () => {
    const { token: passwordToken, userId } = useParams<RawParams>();

    const [ status,, errors, transform ] = useTransform<unknown, RequestFields>((axios, params) => axios.post("/auth/forgot-password-reset", params));

    const { executeRecaptcha } = useGoogleReCaptcha();

    const [ captchaLoading, setCaptchaLoading ] = useState(false);

    const [ passwordStrenght, setPasswordStrenght ] = useState<PasswordStrenght>(null);
    const [ passwordReset, setPasswordReset ] = useState(false);

    const onSubmit = async (values: Fields, { setFieldError }: FormikHelpers<{ password: string, confirmPassword: string }>) => {
        setCaptchaLoading(true);
        if(captchaLoading || !executeRecaptcha) {
            setCaptchaLoading(false);
            return setFieldError("serverError", "Captcha failed to load, try refreshing the page.");
        }
        const token = await executeRecaptcha().finally(() => setCaptchaLoading(false));

        transform({
            ...values,
            userId,
            passwordToken,
            token
        });
    };

    const checkPasswordStrength = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        if(!password) {
            setPasswordStrenght(null);
            return;
        }
    };

    const formik = useFormik<{ password: string, confirmPassword: string }>({
        initialValues: {
            password: "",
            confirmPassword: ""
        },
        validationSchema: ResetSchema,
        validateOnChange: false,
        onSubmit: (values, helpers) => onSubmit({ newPassword: values.password }, helpers)
    });

    useEffect(() => {
        if(status === "errored")
            return formik.setFieldError("serverError", errors.join("; "));

        if(status !== "done") return;
        setPasswordReset(true);
    }, [status]);

    return (
        <Wrapper>
            <Card>
                <CardTitle>Password reset</CardTitle>
                {Object.entries(formik.errors).filter(([k]) => !Object.keys(formik.values).includes(k)).map(([k, v]) => <span key={k} style={{ color: "red" }}>{v}</span>)}
                {passwordReset && <span style={{ color: "green" }}>Password reset successfully!</span>}
                <FormWrapper onSubmit={formik.handleSubmit}>
                    <CardDiv>
                        <InputLabel text="Password" error={formik.errors.password} />
                        <div style={{ position: "relative" }}>
                            <TextInput type="password" placeholder="******" name="password" formik={formik} onChange={e => {
                                formik.handleChange(e);
                                checkPasswordStrength(e);
                            }}/>
                            <PasswordStrenghtIndicator visible={passwordStrenght !== null} strenght={passwordStrenght as NonNullable<PasswordStrenght>} />
                        </div>
                    </CardDiv>
                    <CardDiv>
                        <InputLabel text="Confirm Password" error={formik.errors.confirmPassword} />
                        <TextInput type="password" placeholder="******" name="confirmPassword" formik={formik} />
                    </CardDiv>
                    {passwordReset ?
                        <LinkButton to="/" text="Back to login" />
                        :
                        <Button disabled={captchaLoading || status === "loading"}>RESET</Button>
                    }
                    <CardText>
                        login instead?
                        <LinkWrapper to="/">
                            login
                        </LinkWrapper>
                    </CardText>
                </FormWrapper>
            </Card>
        </Wrapper>
    );
};

export default withCaptcha(PasswordReset);