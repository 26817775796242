import { useFormik } from "formik";
import { McPasteSite } from "models";
import { useEffect } from "react";
import { useState } from "react";
import { Snowflake } from "snowflake";
import styled from "styled-components";
import * as yup from "yup";

import { useStoreState } from "../../../../hooks/state";
import { useHttp, useTransform } from "../../../../hooks/useHttp";
import { withNav } from "../../../../hooks/withNavbar";
import { capitalize } from "../../../../lib/text";
import Button from "../../../elements/Button";
import TextInput from "../../../elements/TextInput";
import AdminMcPasteContainer from "./admin/AdminMcPasteContainer";
import McPasteSiteBox from "./McPasteSiteBox";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem 2rem;
    gap: 2rem;
`;

const ErrorContainer = styled.div`
    height: 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const RedirectInputContainer = styled.form`
    display: flex;
    max-width: 1200px;
    width: 100%;
`;

const RedirectInput = styled(TextInput)`
    height: 40px;
    flex-basis: 80%;
    flex-shrink: 0;
    border-radius: 4px 0 0 4px;
`;

const RedirectInputButton = styled(Button)`
    padding: 0.6rem;
    border-radius: 0 4px 4px 0;
`;

const NewSiteSchema = yup.object().required().shape({
    site: yup.string().required()
});

const McPasteContainer = () => {
    const [ loadingStatus, sitesResponse ] = useHttp<McPasteSite[]>((axios) => axios.get("/modules/mcpaste"));
    const { id, admin } = useStoreState(state => state.user.data!);
    const [ sites, setSites ] = useState<McPasteSite[]>([]);

    const [ status, data, errors, transform ] = useTransform<{ id: string, token: string }, { site: string }>((axios, data) => axios.post("/modules/mcpaste/create", data));

    useEffect(() => {
        if(loadingStatus !== "done" || !sitesResponse) return;
        setSites(sitesResponse);
    }, [loadingStatus, sitesResponse]);

    useEffect(() => {
        if(status !== "done" && status !== "errored") return;
        if(status === "errored")
            return formik.setFieldError("serverError", errors.join("; "));

        setSites(sites => [ ...sites, {
            id: (data?.id ?? "") as Snowflake,
            userId: id,
            site: formik.values.site,
            token: data!.token,
            plan: "Free"
        } ]);
    }, [status]);

    const formik = useFormik<{ site: string }>({
        initialValues: {
            site: ""
        },
        validationSchema: NewSiteSchema,
        validateOnChange: false,
        onSubmit: transform
    });

    const onSiteRemove = (site: string) => {
        setSites(sites => sites.filter(curr => curr.site !== site));
    };

    if(admin)
        return <AdminMcPasteContainer
            formik={formik}
            sites={sites}
            loading={loadingStatus === "loading"}
            disabled={status === "loading"}
            onSiteRemove={onSiteRemove}
        />;

    return (
        <Container>
            <ErrorContainer>
                {Object.values(formik.errors).map(it => <span key={it} style={{ color: "red" }}>{capitalize(it)}</span>)}
            </ErrorContainer>
            <RedirectInputContainer onSubmit={formik.handleSubmit}>
                <RedirectInput formik={formik} name="site" placeholder="sub.domain.tld" />
                <RedirectInputButton disabled={status === "loading"}>ADD</RedirectInputButton>
            </RedirectInputContainer>
            <ContentContainer>
                {sites.map(it => <McPasteSiteBox key={it.id} site={it} onSiteRemove={onSiteRemove} />)}
            </ContentContainer>
        </Container>
    );
};

export default withNav(McPasteContainer);