import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Snowflake } from "snowflake";
import styled from "styled-components";

import { useHttp } from "../../../../hooks/useHttp";
import { withNav } from "../../../../hooks/withNavbar";
import ActionButton from "../../../elements/ActionButton";
import PanelContainer from "./PanelContainer";
import PanelCreateForm from "./PanelCreateForm";
import Button from "../../../elements/Button";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
`;

const PanelListContainer = styled.div`
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

export type GamepanelPanel = {
    id: Snowflake;
    host: string;
    admin_email: string;
    deleted_at: number | null;
    suspended_at: number | null;
};

const Panels = () => {
    const [status, panelsData, , refetch] = useHttp<GamepanelPanel[]>((axios) =>
        axios.get("/admin/game-panel/panels"),
    );

    const [panels, setPanels] = useState<GamepanelPanel[]>([]);

    const sorted = useMemo(
        () => panels.sort((a, b) => Number(BigInt(a.id) - BigInt(b.id))),
        [panels],
    );

    useEffect(() => {
        if (status !== "done" || !panelsData) return;

        setPanels(panelsData);
    }, [status, panelsData]);

    const createCallback = useCallback(() => refetch(), [refetch]);

    return (
        <Container>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    gap: "1rem",
                }}
            >
                <Link to={"/admin/panels/feedbacks"} style={{ textDecoration: "none" }}>
                    <Button style={{ width: "max-content", padding: "0.6rem" }}>
                        Alpha feedback
                    </Button>
                </Link>
                <PanelCreateForm onCreate={createCallback} />
            </div>
            <PanelListContainer>
                {sorted.map((panel) => (
                    <PanelContainer
                        key={panel.id}
                        panel={panel}
                        onDelete={() => setPanels(panels.filter((it) => it.id !== panel.id))}
                    />
                ))}
            </PanelListContainer>
        </Container>
    );
};

export default withNav(Panels);
