import { useFormik } from "formik";
import { Redirect, User } from "models";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";

import { useStoreState } from "../../../../hooks/state";
import { useHttp, useTransform } from "../../../../hooks/useHttp";
import { withNav } from "../../../../hooks/withNavbar";
import { capitalize } from "../../../../lib/text";
import ErrorContainer from "../../../elements/ErrorContainer";
import LimitedContentContainer from "../../../elements/LimitedContentContainer";
import LoadingSpinner from "../../../elements/LoadingSpinner";
import TableContainer from "../../../elements/TableContainer";
import TableContentContainer from "../../../elements/TableContentContainer";
import TableHeading from "../../../elements/TableHeading";
import TableHeadingContainer from "../../../elements/TableHeadingContainer";
import TableInput from "../../../elements/TableInput";
import TableInputButton from "../../../elements/TableInputButton";
import TableInputContainer from "../../../elements/TableInputContainer";
import TableWrapper from "../../../elements/TableWrapper";
import RedirectLinkRow from "./RedirectLinkRow";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
`;

const RedirectSchema = yup.object().required().shape({
    link: yup.string().required()
});

const RedirectContainer = () => {
    const { id, admin } = useStoreState(state => state.user.data!);

    const [ loadingStatus, redirectsResponse ] = useHttp<Redirect[]>((axios) => axios.get("/modules/redirect"));
    const [ ,users ] = useHttp<Omit<User, "password">[]>(axios => axios.get("/admin/users"), { doRequest: admin });
    const [ redirects, setRedirects ] = useState<Redirect[]>([]);

    const [ status, data, errors, transform ] = useTransform<{ short: string }, { link: string }>((axios, data) => axios.post("/modules/redirect/create", data));

    useEffect(() => {
        if(loadingStatus !== "done" || !redirectsResponse) return;
        setRedirects(redirectsResponse);
    }, [loadingStatus, redirectsResponse]);

    useEffect(() => {
        if(status !== "done" && status !== "errored") return;
        if(status === "errored")
            return formik.setFieldError("serverError", errors.join("; "));

        setRedirects((redirects) => [ ...redirects, { userId: id, short: data!.short, link: formik.values.link, enabled: true } ]);
    }, [status]);

    const formik = useFormik<{ link: string }>({
        initialValues: {
            link: ""
        },
        validationSchema: RedirectSchema,
        validateOnChange: false,
        onSubmit: transform
    });

    const onRedirectRemove = (redirect: Redirect) => {
        setRedirects((redirects) => redirects.filter(curr => curr.short !== redirect.short));
    };

    return (
        <Container>
            <ErrorContainer>
                {Object.values(formik.errors).map(it => <span key={it} style={{ color: "red" }}>{capitalize(it)}</span>)}
            </ErrorContainer>
            <LimitedContentContainer>
                <TableInputContainer onSubmit={formik.handleSubmit}>
                    <TableInput name="link" placeholder="https://sub.domain.tld" formik={formik} />
                    <TableInputButton disabled={status === "loading"}>ADD</TableInputButton>
                </TableInputContainer>
                <TableWrapper>
                    <TableContainer>
                        <TableHeadingContainer>
                            <TableHeading>SHORT</TableHeading>
                            <TableHeading>LINK</TableHeading>
                            <TableHeading $center>ENABLED</TableHeading>
                            {admin && <TableHeading $center>OWNER</TableHeading>}
                            <TableHeading $center>ACTIONS</TableHeading>
                        </TableHeadingContainer>
                        <TableContentContainer>
                            {loadingStatus === "loading" && <div style={{ display: "flex", justifyContent: "center", padding: "1rem" }}><LoadingSpinner /></div>}
                            {redirects.sort((a) => a.userId === id ? -1 : 1).map(it =>
                                <RedirectLinkRow key={it.short} ownerName={users?.find(user => user.id === it.userId)?.username ?? it.userId} onRedirectRemove={onRedirectRemove} redirect={it} />
                            )}
                        </TableContentContainer>
                    </TableContainer>
                </TableWrapper>
            </LimitedContentContainer>
        </Container>
    );
};

export default withNav(RedirectContainer);