import { TableMap } from "datalayer";
import { Snowflake } from "snowflake";

export const Services = ["mcpaste"] as const;
export type ServiceType = typeof Services[number];

export const AllServices = Services.concat() as string[]

export const isService = (service: string): service is ServiceType => {
    return AllServices.includes(service);
}

type PlanTables = { [K in keyof TableMap]: TableMap[K] extends { userId: Snowflake, plan: string } ? K : never }[keyof TableMap];

export const ServiceTableMap: { [k in ServiceType]: PlanTables } = {
    "mcpaste": "mcpaste"
}