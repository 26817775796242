import { useEffect, useState } from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";

import { useHttp } from "../../../../hooks/useHttp";
import { formatOnlyDate, subtractDateDays } from "../../../../lib/date";
import SimpleLineChart from "../../../elements/SimpleLineChart";
import { ChartContainer } from "../Statistics";

type ChartablePaste = { count: number, date: string }

type Leaderboard = { [k: string]: number }
type ChartableLeaderboard = { host: string, count: number }[]

const pieChartColors = ["#8884d8", "#83a6ed", "#8dd1e1", "#82ca9d", "#a4de6c", "#d0ed57", "#edca57", "#edb157", "#ed8e57", "#ed6d57"];

const McPasteCharts = () => {
    const [ pastes, setPastes ] = useState<ChartablePaste[]>(
        new Array(30).fill(0).map((_, i) => ({ count: 0, date: formatOnlyDate(subtractDateDays(new Date(), i)) }))
    );
    const [ leaderboard, setLeaderboard ] = useState<ChartableLeaderboard>([]);

    const [ pasteStatus, pasteData ] = useHttp<ChartablePaste[]>(axios => axios.get("/admin/chart/mcpaste"));
    const [ leaderboardStatus, leaderboardData ] = useHttp<Leaderboard>(axios => axios.get("/admin/chart/mcpaste/leaderboard"), { cache: false });
    
    useEffect(() => {
        if(pasteStatus !== "done" || !pasteData) return;
        setPastes(pasteData);
    }, [pasteStatus, pasteData]);

    useEffect(() => {
        if(leaderboardStatus !== "done" || !leaderboardData) return;
        setLeaderboard(Object.entries(leaderboardData).map(([k, v]) => ({ host: k, count: v })));
    }, [leaderboardStatus, leaderboardData]);

    return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
            <SimpleLineChart
                title="McPaste pastes (30d)"
                width={900}
                data={pastes}
                lineDataKey="count"
                nameDataKey="date"
                tooltipPayloadMod={(i) => `Pastes: ${i}`}
            />
            <ChartContainer>
                <span>Paste leaderboard</span>
                <PieChart width={300} height={250}>
                    <Pie data={leaderboard} dataKey={"count"} nameKey={"host"} fill="#82ca9d" label>
                        {leaderboard.map((_, i) => <Cell key={i} fill={pieChartColors[i]} />)}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ChartContainer>
            <ChartContainer>
                <span>Paste leaderboard</span>
                <table>
                    <tr>
                        <th>Host</th>
                        <th>Pastes</th>
                    </tr>
                    {leaderboard.map((entry, i) =>
                        <tr key={i} >
                            <td>{entry.host}</td>
                            <td>{entry.count}</td>
                        </tr>
                    )}
                </table>
            </ChartContainer>
        </div>
    );
};

export default McPasteCharts;