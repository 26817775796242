import styled from "styled-components";

const Button = styled.button<{ width?: string }>`
    border: none;
    background-color: #408CFF;
    padding: 0.8rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
    width: ${p => p.width ?? "100%"};
    transition: 100ms linear;
    ${p => p.disabled ? "" : "cursor: pointer"};
    gap: 0.6rem;

    &:hover {
        background-color: #3d7fe9;
        transition: 100ms linear;
    }

    &[disabled] {
        background-color: #244b88;
        transition: 100ms linear;
    }
`;

export default Button;