import styled from "styled-components";

const TableHeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #989898;
    width: 100%;
    padding: 2px 0.4rem;
`;

export default TableHeadingContainer;