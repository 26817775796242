import styled from "styled-components";

const ActionButton = styled.button<{ $red?: boolean }>`
    border: none;
    background-color: ${p => p.$red ? "#ff7d7d" : "#5F5F5F"};
    padding: 0.4rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 0.8rem;
    transition: 100ms linear;
    width: 65px;
    ${p => p.disabled ? "" : "cursor: pointer"};

    &:hover {
        background-color: ${p => p.$red ? "#fd6e6e" : "#4e4e4e"};
        transition: 100ms linear;
    }

    &[disabled] {
        background-color: ${p => p.$red ? "#eb5454" : "#3f3f3f"};
        transition: 100ms linear;
    }
`;

export default ActionButton;
