import { McPasteSite } from "models";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import { useHttp } from "../../../hooks/useHttp";

import { withNav } from "../../../hooks/withNavbar";
import ActionButton from "../../elements/ActionButton";
import LoadingSpinner from "../../elements/LoadingSpinner";
import { ActiveService } from "../profile/billing/ActiveServices";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    padding: 1rem;
    gap: 2rem;
`;

const ServiceManageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 1rem;
    background-color: white;
    padding: 2rem;
    border-radius: 4px;
    width: 400px;
    height: 240px;
    text-align: center;

    span:first-child {
        font-size: 1.5rem;
    }
`

const DashboardActionButton = styled(ActionButton)`
    padding: 0.6rem;
    font-size: 1rem;
    width: 100%;
`


const Dashboard = () => {
    const [ redirectStatus, redirects ] = useHttp<Redirect[]>(axios => axios.get("/modules/redirect"));
    const [ mcpasteStatus, sites ] = useHttp<McPasteSite[]>(axios => axios.get("/modules/mcpaste"));
    const [ serviceStatus, active ] = useHttp<ActiveService[]>(axios => axios.get("/payments/active"));

    return (
        <Container>
            <ServiceManageContainer>
                <span>Redirects</span>
                <span>Easily create short urls that will redirect to whatever site you want!</span>
                {(redirectStatus === "done" && redirects) && <span style={{ fontSize: "1.1rem" }}><b>Total redirects&#58; </b>{redirects.length}</span>}
                <Link to="/modules/redirect" style={{ textDecoration: "none" }}><DashboardActionButton>Manage</DashboardActionButton></Link>
            </ServiceManageContainer>
            <ServiceManageContainer>
                <span>McPaste</span>
                <span>Manage the McPaste pterodactyl addon!</span>
                {(mcpasteStatus === "done" && sites) && <span style={{ fontSize: "1.1rem" }}><b>Total sites registered&#58; </b>{sites.length}</span>}
                <Link to="/modules/mcpaste" style={{ textDecoration: "none" }}><DashboardActionButton>Manage</DashboardActionButton></Link>
            </ServiceManageContainer>
            <ServiceManageContainer>
                <span>Estimated monthly costs</span>
                {(serviceStatus === "done" && active) ?
                    <span style={{ fontSize: "1.3rem" }}>US&#36; {active?.reduce((acc, curr) => acc + curr.amount, 0)}</span> :
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <LoadingSpinner size="SMALL" />
                    </div>
                }
                {(serviceStatus === "done" && active) && <span style={{ fontSize: "1.1rem" }}><b>Services subscriptions&#58; </b>{active.length}</span>}
                <Link to="/account/billing" style={{ textDecoration: "none" }}><DashboardActionButton $red>Manage</DashboardActionButton></Link>
            </ServiceManageContainer>
        </Container>
    );
};

export default withNav(Dashboard);
