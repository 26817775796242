import { Redirect } from "models";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";

import { useStoreActions, useStoreState } from "../../../../hooks/state";
import { useTransform } from "../../../../hooks/useHttp";
import ActionButton from "../../../elements/ActionButton";
import ActionButtonContainer from "../../../elements/ActionButtonContainer";
import TableEntry from "../../../elements/TableEntry";
import TableRowContainer from "../../../elements/TableRowContainer";

const ShortLinkWrapper = styled.a`
    color: unset;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const LinkWrapper = styled.a`
    color: #408CFF;
    cursor: pointer;
`;

const RedirectLinkRow = ({ onRedirectRemove, redirect, ownerName }: { onRedirectRemove: (redirect: Redirect) => void, redirect: Redirect, ownerName: string }) => {
    const { id, admin } = useStoreState(state => state.user.data!);
    const [ enabled, setEnabled ] = useState(redirect.enabled);
    
    const activateModal = useStoreActions(state => state.modals.activateModal);

    const [ toggleStatus, data, , toggleTransform ] = useTransform<{ newState: boolean }>((axios) => axios.post("/modules/redirect/toggle", { short: redirect.short }));
    const [ deleteStatus, , , deleteTransform ] = useTransform(axios => axios.delete(`/modules/redirect/delete/${redirect.short}`));

    useEffect(() => {
        if(toggleStatus !== "done" || !data) return;
        redirect.enabled = data.newState;
        setEnabled(redirect.enabled);
    }, [toggleStatus]);

    useEffect(() => {
        setEnabled(redirect.enabled);
    }, [redirect]);

    useEffect(() => {
        if(deleteStatus !== "done") return;

        onRedirectRemove(redirect);
    }, [deleteStatus]);

    const handleDelete = () => {
        activateModal({
            type: "confirm",
            data: {
                title: "Delete redirect?",
                message: "Are you sure you want to delete this redirect?",
                confirmButton: "Delete",
                onConfirm: deleteTransform
            }
        })
    }
 
    return (
        <TableRowContainer>
            <TableEntry><ShortLinkWrapper href={`https://hm4.dev/r/${redirect.short}`} target="_blank"><span style={{ color: "#5F5F5F" }}>hm4.dev/r/</span>{redirect.short}</ShortLinkWrapper></TableEntry>
            <TableEntry><LinkWrapper href={redirect.link} target="_blank">{redirect.link}</LinkWrapper></TableEntry>
            <TableEntry $center>{enabled ? "Yes" : "No"}</TableEntry>
            {admin && <TableEntry $center>{redirect.userId === id ? "You" : ownerName}</TableEntry>}
            <TableEntry $center>
                <ActionButtonContainer>
                    <ActionButton onClick={toggleTransform} disabled={toggleStatus === "loading"}>{enabled ? "Disable" : "Enable"}</ActionButton>
                    <ActionButton onClick={handleDelete} disabled={deleteStatus === "loading"} $red={true}>Delete</ActionButton>
                </ActionButtonContainer>
            </TableEntry>
        </TableRowContainer>
    );
};

export default RedirectLinkRow;