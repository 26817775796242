import { useEffect, useState } from "react";
import styled from "styled-components";
import yubikeyLogo from "url:/assets/yubikey.png";

import { useAuth } from "../../../hooks/useAuth";
import { doRequest, useHttp, useTransform } from "../../../hooks/useHttp";
import { decodeFieldsToArrayBuffer } from "../../../lib/buffers";
import { decodeAssertion, encodeAssertResponse, encodeAttestationResponse, TransmittableAssertCredential, TransmittablePublicKeyCredential } from "../../../lib/webauthn";
import LoadingSpinner from "../../elements/LoadingSpinner";
import Modal from "../../elements/Modal";
import { AuthenticationMethodContainer } from "./ProfileTwoFactor";

const LinkWrapper = styled.a`
    color: #408CFF;
    text-decoration: underline;
    cursor: pointer;
`;

type WebauthnData = {
    enabled: boolean,
    publicKey: string
}

const Webauthn = () => {
    const { setToken } = useAuth();
    
    const [ status, responseData ] = useHttp<WebauthnData>(axios => axios.get("/user/webauthn"), { cache: false });

    const [ data, setData ] = useState<WebauthnData>();
    const [ webauthnErrors, setWebauthnErrors ] = useState<string[]>([]);

    const [ attestateStatus, attestateData, attestateErrors, attestateTransform ] = useTransform<{ newToken: string }, TransmittablePublicKeyCredential>(
        (axios, credentialData) => axios.post("/user/webauthn/attestate", credentialData),
        { autoProccessErrors: false }
    );

    const [ assertStatus, assertData, assertErrors, assertTransform ] = useTransform<{ newToken: string }, TransmittableAssertCredential>(
        (axios, credentialData) => axios.post("/user/webauthn/assert-remove", credentialData),
        { autoProccessErrors: false }
    );

    const [ showWebauthn, setShowWebauthn ] = useState(false);

    useEffect(() => {
        if(status !== "done" || !responseData) return;

        setData(responseData);
    }, [status, responseData]);

    const createCredential = async () => {
        const [ data, errors ] = await doRequest<{ attestation: PublicKeyCredentialCreationOptions }>(axios => axios.get("/user/webauthn/opts"), { autoProccessErrors: false });
        if(errors.length)
            return setWebauthnErrors(errors);
        if(!data) return;
        const { attestation: response } = data;
        const attestation = decodeFieldsToArrayBuffer(response, ["challenge", "id"], ["rp"]);
        let res: Credential;
        try {
            const createRes = await navigator.credentials.create({ publicKey: attestation });
            if(!createRes) throw new Error();
            res = createRes;
        } catch {
            return setWebauthnErrors(["Failed to authenticate"]);
        }

        const credential = encodeAttestationResponse(res as PublicKeyCredential);
        attestateTransform(credential);
    };

    const removeCredential = async () => {
        const [ data, errors ] = await doRequest<{ assertion: PublicKeyCredentialRequestOptions }>(axios => axios.get("/user/webauthn/assert-opts"), { autoProccessErrors: false });
        if(errors.length)
            return setWebauthnErrors(errors);
        if(!data) return;
        const assertion = decodeAssertion(data.assertion);
        let res: Credential;
        try {
            const createRes = await navigator.credentials.get({ publicKey: assertion });
            if(!createRes) throw new Error();
            res = createRes;
        } catch {
            return setWebauthnErrors(["Failed to authenticate"]);
        }

        const credential = encodeAssertResponse(res as PublicKeyCredential);
        assertTransform(credential);
    };

    useEffect(() => {
        if(!showWebauthn || !data) return;

        setWebauthnErrors([]);
        data.enabled ? removeCredential() : createCredential();
    }, [showWebauthn]);

    useEffect(() => {
        if(attestateStatus !== "done" || !attestateData) return;

        setShowWebauthn(false);
        setToken(attestateData.newToken);
        setTimeout(() => setData(d => d ? ({ ...d, enabled: !d.enabled }) : undefined), 200);
    }, [attestateStatus]);

    useEffect(() => {
        if(assertStatus !== "done" || !assertData) return;

        setShowWebauthn(false);
        setToken(assertData.newToken);
        setTimeout(() => setData(d => d ? ({ ...d, enabled: !d.enabled }) : undefined), 200);
    }, [assertStatus]);

    useEffect(() => {
        setWebauthnErrors([...assertErrors, ...attestateErrors]);
    }, [assertErrors, attestateErrors]);

    if(status === "loading" || !data)
        return <></>;

    return (
        <AuthenticationMethodContainer>
            <Modal title={data.enabled ? "Confirm Security key" : "Setup Security key"} active={showWebauthn} onExit={() => setShowWebauthn(false) }>
                {webauthnErrors.map((err, i) => <span key={i} style={{ color: "red" }}>{err}</span>)}

                <LoadingSpinner
                    size="LARGE"
                    show={!webauthnErrors.length}
                    img={<img src={yubikeyLogo} style={{ width: "42px", transform: "translate(-50%, -50%) rotate(60deg)", transformOrigin: "center" }}/>}
                    disabled={<img src={yubikeyLogo} style={{ width: "42px", transform: "rotate(60deg)" }}/>}
                />

                {!webauthnErrors.length && <span style={{ textAlign: "center" }}>Insert your security key</span> }
            </Modal>
            <span>Security key</span>
            <LinkWrapper onClick={() => setShowWebauthn(true)}>{data.enabled ? "disable" : "enable"}</LinkWrapper>
        </AuthenticationMethodContainer>
    );
};

export default Webauthn;