import { faPaypal } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { MethodHash, PaymentMethod, Transaction } from "models";
import { FC } from "react";
import styled from "styled-components";
import { formatDateTime } from "../../../../lib/date";
import { capitalize } from "../../../../lib/text";
import Icon from "../../../elements/Icon";
import TableEntry from "../../../elements/TableEntry";
import TableRowContainer from "../../../elements/TableRowContainer";
import { CardDigitQuartet, getPaymentMethodIconElement, MethodIconContainer } from "./PaymentMethodContainer";

const Centered = styled.div`
    text-align: center;
`

const FlexRow = styled.div`
    display: flex;
    align-items: center;
    gap: 0.6rem;
`

const TransactionRow: FC<{ transaction: Transaction, methodUsed: PaymentMethod | MethodHash }> = ({ transaction, methodUsed }) => {
    return (
        <TableRowContainer>
            <TableEntry basis={50}>
                <FlexRow>
                    <MethodIconContainer>
                        {typeof methodUsed === "string" ?
                            methodUsed.startsWith("paypal:") ?
                                <Icon icon={faPaypal} color={"#0079C1"} size="lg" />
                            : <Icon icon={faCreditCard} size="lg" color={"#525252"} />
                        : getPaymentMethodIconElement(methodUsed)}
                    </MethodIconContainer>
                    {typeof methodUsed === "string" ? methodUsed.startsWith("paypal:") ? methodUsed.slice("paypal:".length) : methodUsed : 
                    <>
                        {methodUsed.type === "card" && <CardDigitQuartet />}
                        {methodUsed.type === "card" ? methodUsed.last4 : methodUsed.email}
                    </>
                    }
                </FlexRow>
            </TableEntry>
            <TableEntry basis={10}>{capitalize(transaction.service)}</TableEntry>
            <TableEntry basis={10}>{capitalize(transaction.plan)}</TableEntry>
            <TableEntry basis={10}><Centered>&#36;{transaction.amount.toFixed(2)}</Centered></TableEntry>
            <TableEntry><Centered>{formatDateTime(new Date(transaction.created_at))}</Centered></TableEntry>
        </TableRowContainer>
    )
}

export default TransactionRow;