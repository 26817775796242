import { useTransform } from "../../../../hooks/useHttp";
import TableInput from "../../../elements/TableInput";
import TableInputButton from "../../../elements/TableInputButton";
import TableInputContainer from "../../../elements/TableInputContainer";
import { useFormik } from "formik";
import * as yup from "yup";
import { FC, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
`;

const PanelSchema = yup.object().required().shape({
    host: yup.string().required(),
    admin_email: yup.string().required(),
});

type Properties = {
    onCreate: () => void;
};

const PanelCreateForm: FC<Properties> = ({ onCreate }) => {
    const [status, , errors, transform] = useTransform<
        undefined,
        {
            host: string;
            admin_email: string;
        }
    >((axios, data) => axios.post("/admin/game-panel/panels", data));

    const formik = useFormik<{ host: string; admin_email: string }>({
        initialValues: {
            host: "",
            admin_email: "",
        },
        validationSchema: PanelSchema,
        validateOnChange: false,
        onSubmit: transform,
    });

    useEffect(() => {
        if (status !== "done") return;

        formik.resetForm();
        onCreate();
    }, [status, onCreate]);

    return (
        <Container>
            {errors.length !== 0 && <span style={{ color: "red" }}>{errors.join(", ")}</span>}
            <TableInputContainer onSubmit={formik.handleSubmit}>
                <TableInput
                    name="host"
                    placeholder="mygreathost.gamepanel.pro"
                    formik={formik}
                    style={{ flexBasis: "40%" }}
                />
                <TableInput
                    name="admin_email"
                    placeholder="username@example.com"
                    formik={formik}
                    style={{ flexBasis: "40%" }}
                />
                <TableInputButton disabled={status === "loading"}>ADD</TableInputButton>
            </TableInputContainer>
        </Container>
    );
};

export default PanelCreateForm;
