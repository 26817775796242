import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

const SelectContainer = styled.div`
    position: relative;
    width: 100%;
    background: #F2F2F2;
    border: none;
    outline: none;
    cursor: pointer;
    user-select: none;
`

const DropdownContainer = styled.div`
    width: 100%;
    background: #eeeeee;
    position: absolute;
    left: 0;
    top: calc(100% - 4px);
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    overflow: hidden;
`

type Props<T> = {
    values: T[],
    defaultValue: string,
    defaultSelector: (it: T) => string,
    each: (it: T) => JSX.Element,
    onChange: (it: T) => void
}

const SelectDropdown = <T,>({ values, defaultValue, defaultSelector, each, onChange }: Props<T>) => {
    const [ dropped, setDropped ] = useState(false);
    const [ selected, setSelected ] = useState(defaultValue);

    const findBySelector = useCallback((selector: string) => values.find(it => defaultSelector(it) === selector), [values, defaultSelector]);

    const elements = useMemo(() =>
        Object.assign({}, ...values.map(it => ({ [defaultSelector(it)]: each(it) }))) as { [k: string]: JSX.Element }
    , [values, defaultSelector, each]);

    useEffect(() => {
        const item = findBySelector(selected);
        if(item)
            onChange(item);
    }, [selected])

    return (
        <SelectContainer onClick={() => setDropped(d => !d)}>
            {elements[selected]}
            {dropped &&
                <DropdownContainer>
                    {Object.entries(elements).filter(([,it]) => it !== elements[selected]).map(([sel, it]) =>
                        <div key={sel} onClick={() => setSelected(sel)}>
                            {it}
                        </div>
                    )}
                </DropdownContainer>
            }
        </SelectContainer>
    )
}

export default SelectDropdown;