import { AxiosInstance, AxiosResponse } from "axios";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { Snowflake } from "snowflake";

import { useHttp } from "./useHttp";

export const withIdData = <T, P extends string>(propName: P, fetchFn: (axios: AxiosInstance, id: Snowflake) => Promise<AxiosResponse>, Component: FC<{ [k in P]: T } & { [k: string]: unknown }>, Loading: FC, LoadingError: FC<{ errors: string[] }>) => {
    return () => {
        const { id } = useParams<{ id: Snowflake }>();
        const [ status, data, errors ] = useHttp<T>((axios) => fetchFn(axios, id), { cache: false });

        if(status === "loading" || !data)
            return <Loading />;
        
        if(status === "errored")
            return <LoadingError errors={errors} />;

        const props: { [k in P]: T } = { [propName]: data as T } as { [k in P]: T };
        return <Component {...props} />;
    };
};