import styled from "styled-components";

const InputLabelContainer = styled.div`
    padding-left: 0.2rem;
    width: 100%;
    margin-bottom: 0.4rem;
    display: flex;
    justify-content: space-between;

    span:nth-child(2) {
        color: red;
    }
`;

const InputLabel = ({ text, error }: { text: string, error?: string }) => {
    return (
        <InputLabelContainer>
            <span>{text}</span>
            <span>{ error ?? "" }</span>
        </InputLabelContainer>
    );
};

export default InputLabel;