import { useFormik } from "formik";
import { McPasteSite } from "models";
import { FC } from "react";
import styled from "styled-components";

import { useTransform } from "../../../../../hooks/useHttp";
import InputLabel from "../../../../auth/elements/AuthInputLabel";
import ActionButton from "../../../../elements/ActionButton";
import FormikInput from "../../../../elements/FormikInput";
import SectionContainer from "../../../../elements/SectionContainer";
import SectionHeader from "../../../../elements/SectionHeader";
import TextInput from "../../../../elements/TextInput";

const Container = styled.form`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
`

const BiOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 200px;
`

const SubdomainInputContainer = styled.div`
    display: flex;
`

const SuffixInput = styled(TextInput)`
    background-color: #D7D7D7;
    color: #040404;
    width: 140px;
    text-align: center;
`

const ColorInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 42px;
`

const ColorInput = styled(FormikInput)`
    width: 140px;
    border: 2px solid #D5D5D5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    outline: none;
    height: 1.8rem;
`

type Theming = {
    bgColor: string,
    rawTextColor: string,
    saveBtnColor: string,
    saveBtnTextColor: string,
    saveBtnFocusColor: string,
    saveBtnHoverColor: string
}

type Cosmetic = {
    subdomain: string,
    faviconUrl: string
} & Theming

const CosmeticOptions: FC<{ site: McPasteSite }> = ({ site }) => {
    const [ status,, errors, transform ] = useTransform<never, Pick<Cosmetic, "subdomain" | "faviconUrl"> & { theming: Theming }>((axios, data) => axios.post(`/modules/mcpaste/${site.id}/feature/cosmetic/update`, data), { autoProccessErrors: false });

    const theming: Theming = site.theming ? JSON.parse(site.theming) : null;

    const formik = useFormik<Cosmetic>({
        initialValues: {
            subdomain: site.subdomain ?? "",
            faviconUrl: site.faviconUrl ?? "",
            bgColor: theming?.bgColor ?? "#000000",
            rawTextColor: theming?.rawTextColor ?? "#000000",
            saveBtnColor: theming?.saveBtnColor ?? "#000000",
            saveBtnTextColor: theming?.saveBtnTextColor ?? "#000000",
            saveBtnFocusColor: theming?.saveBtnFocusColor ?? "#000000",
            saveBtnHoverColor: theming?.saveBtnHoverColor ?? "#000000"
        },
        onSubmit: values => transform({
            ...values,
            theming: {
                ...values
            }
        })
    });

    return (
        <SectionContainer>
            <SectionHeader>
                <span>Customization</span>
                <span style={{ color: "red" }}>{errors.join("; ")}</span>
                <ActionButton style={{ marginRight: "0.3rem" }} disabled={status === "loading"} onClick={formik.submitForm}>Update</ActionButton>
            </SectionHeader>
            <Container onSubmit={formik.handleSubmit}>
                <BiOptionContainer>
                    <div>
                        <InputLabel text="Subdomain" />
                        <SubdomainInputContainer>
                            <TextInput type="text" name="subdomain" placeholder="custom" formik={formik} />
                            <SuffixInput type="text" value=".mcpaste.com" name="__suffix" formik={formik} disabled />
                        </SubdomainInputContainer>
                    </div>
                    <div>
                        <InputLabel text="Favicon" />
                        <TextInput type="text" name="faviconUrl" placeholder="https://hm4.dev/favicon.ico" formik={formik} />
                    </div>
                </BiOptionContainer>
                <BiOptionContainer>
                    <div>
                        <InputLabel text="Background color" />
                        <ColorInputContainer><ColorInput type="color" name="bgColor" formik={formik} /></ColorInputContainer>
                    </div>
                    <div>
                        <InputLabel text="Text color" />
                        <ColorInputContainer><ColorInput type="color" name="rawTextColor" formik={formik} /></ColorInputContainer>
                    </div>
                </BiOptionContainer>
                <BiOptionContainer>
                    <div>
                        <InputLabel text="Save button color" />
                        <ColorInputContainer><ColorInput type="color" name="saveBtnColor" formik={formik} /></ColorInputContainer>
                    </div>
                    <div>
                        <InputLabel text="Save button text color" />
                        <ColorInputContainer><ColorInput type="color" name="saveBtnTextColor" formik={formik} /></ColorInputContainer>
                    </div>
                </BiOptionContainer>
                <BiOptionContainer>
                    <div>
                        <InputLabel text="Save button focus color" />
                        <ColorInputContainer><ColorInput type="color" name="saveBtnFocusColor" formik={formik} /></ColorInputContainer>
                    </div>
                    <div>
                        <InputLabel text="Save button hover color" />
                        <ColorInputContainer><ColorInput type="color" name="saveBtnHoverColor" formik={formik} /></ColorInputContainer>
                    </div>
                </BiOptionContainer>
            </Container>
        </SectionContainer>
    );
};

export default CosmeticOptions;